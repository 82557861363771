/**
 * @generated SignedSource<<85388245f48df84f64a8aa86d5e81425>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
export type WriterTypeEnum = "BROKER" | "LESSOR" | "TENANT";
import { FragmentRefs } from "relay-runtime";
export type ArticleCarouselItem_article$data = {
  readonly area: string | null;
  readonly id: string;
  readonly originalId: string;
  readonly region: {
    readonly name: string;
    readonly name1: string;
    readonly name2: string;
    readonly name3: string;
  };
  readonly status: ArticleStatusEnum;
  readonly writerType: WriterTypeEnum | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleAreaInfoText_article" | "ArticleItemWrapper_article" | "ArticleSalesTypeText_article" | "ArticleStatusBadge_article" | "ArticleThumbnail_article" | "ArticleTradeTypeAndPriceText_article" | "WatchButton_article">;
  readonly " $fragmentType": "ArticleCarouselItem_article";
};
export type ArticleCarouselItem_article$key = {
  readonly " $data"?: ArticleCarouselItem_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCarouselItem_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deposit",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyPay",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCarouselItem_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleItemWrapper_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "ArticleSalesTypeText_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "salesTypeV2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "etcType",
                  "storageKey": null
                }
              ],
              "type": "EtcSalesType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "ArticleTradeTypeAndPriceText_article",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "ArticleMainTrade_article",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "trades",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "preferred",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "ShortTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "MonthTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "yearlyPay",
                      "storageKey": null
                    }
                  ],
                  "type": "YearTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "type": "BorrowTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    }
                  ],
                  "type": "BuyTrade",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleThumbnail_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "ArticleAreaInfoText_article",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "floor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topFloor",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleStatusBadge_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WatchButton_article"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "807ad87b4a4b87dcf217658277d4e973";

export default node;
