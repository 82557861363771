import { Spacer } from '@daangn/realty-react/ui';
import { HStack } from 'components/Base/Stack/HStack';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { actionToast, TActionToastState } from 'utils/actionToast';
import Logger from 'utils/Logger';
import { getSafeArea } from 'utils/style';

type Props = {
  toastState: TActionToastState;
};

const OFFSET_GAP = 10;
const SAFE_AREA_INSET_BOTTOM = getSafeArea().bottom;

const ActionToast = ({ toastState }: Props) => {
  const offset = (toastState.offset ?? 0) + OFFSET_GAP;
  const bottom = offset > SAFE_AREA_INSET_BOTTOM ? offset : SAFE_AREA_INSET_BOTTOM + OFFSET_GAP;

  useEffect(() => {
    if (toastState.event) {
      Logger.track('show_toast', toastState.event.params);
    }
  }, []);

  useEffect(() => {
    if (toastState.duration) {
      const timer = setTimeout(() => {
        actionToast.close();
      }, toastState.duration);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      className="rounded-1.5 bg-palette-gray-1000 z-toast fixed bottom-0 left-0 right-0 mx-4 min-h-[44px] px-[16px] py-[11.5px]"
      style={{
        bottom,
      }}
      onClick={() => actionToast.close()}
    >
      <HStack spacing={10}>
        {toastState.icon && <div>{toastState.icon}</div>}
        <p className="t4-regular text-palette-gray-00 word-break-text">{toastState.message}</p>
        <Spacer />
        {toastState.action && (
          <button
            className="t4-bold text-fg-brand shrink-0 p-0"
            onClick={(e) => {
              toastState.action?.onClick?.(e);
              toastState.event && Logger.track('click_toast_action', toastState.event.params);
            }}
          >
            {toastState.action.label}
          </button>
        )}
      </HStack>
    </motion.div>
  );
};

export default ActionToast;
