import type { DeploymentListQuery } from '__generated__/DeploymentListQuery.graphql';
import BoxButton from 'components/Base/BoxButton';
import { HStack } from 'components/Base/Stack/HStack';
import { Stack } from 'components/Base/Stack/Stack';
import { Text } from '@seed-design/react';
import { graphql, useLazyLoadQuery } from 'react-relay';

type Props = {
  onClick: (endpoint: string) => void;
};

const DeploymentList = ({ onClick }: Props) => {
  const { kontrolDeployments } = useLazyLoadQuery<DeploymentListQuery>(
    graphql`
      query DeploymentListQuery {
        kontrolDeployments {
          title
          endpoints {
            host
            protocol
            backendServiceName
          }
        }
      }
    `,
    {}
  );

  const apiDeployments = kontrolDeployments
    .map((deployment) => ({
      title: deployment.title,
      endpoint: deployment.endpoints.find(
        (endpoint) =>
          endpoint.protocol === 'http' &&
          endpoint.host.includes('karrotmarket') &&
          // 외부 공유용 path 거르기 (ex) 부동산원)
          !endpoint.host.includes('/reb')
      ),
    }))
    .filter((deployment) => !!deployment.endpoint);

  return (
    <Stack spacing={8}>
      <Text textStyle="t4Bold">Preview API 배포 목록</Text>
      <Stack spacing={4}>
        {apiDeployments.map((deployment) => (
          <HStack key={deployment.title}>
            <Text textStyle="t4Bold">
              {deployment.title} ({deployment.endpoint?.host})
            </Text>
            <BoxButton
              variant="primaryLow"
              flexShrink={0}
              onClick={() => onClick(`https://${deployment.endpoint?.host}`)}
            >
              적용
            </BoxButton>
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
};

export default DeploymentList;
