import { vars } from '@seed-design/css/vars';
import { SectionContainer } from 'components/Base/Container';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { Stack } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';

const ArticleDetailPageSkeleton = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        background: vars.$color.bg.layerDefault,
      }}
    >
      <Placeholder.Box className="h-[100vw] w-full" />
      <SectionContainer>
        <div className="pt-5">
          <HStack spacing={12}>
            <Placeholder.Box className="h-[48px] w-[48px] rounded-full" />
            <Stack spacing={2}>
              <Placeholder.Text className="t5-bold">당근마켓</Placeholder.Text>
              <Placeholder.Text className="t4-regular">서초동</Placeholder.Text>
            </Stack>
          </HStack>

          <div className="py-4">
            <Placeholder.Divider />
          </div>

          <Stack spacing={8}>
            <Stack spacing={4}>
              <Placeholder.Text className="t4-regular">오픈형 원룸</Placeholder.Text>
              <Placeholder.Text className="t9-bold">월세 500/40</Placeholder.Text>
            </Stack>
            <div className="py-3">
              <Placeholder.Text className="t4-bold rounded-2.5 w-full border border-transparent p-3.5">
                직거래로 아끼는 비용
              </Placeholder.Text>
            </div>
            <div>
              <Placeholder.Text className="t4-regular">
                8일 전 · 채팅 0 · 관심 0 · 조회 7
              </Placeholder.Text>
            </div>
          </Stack>
        </div>
      </SectionContainer>
    </div>
  );
};

export default ArticleDetailPageSkeleton;
