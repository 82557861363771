import MainSectionSkeleton from 'components/Base/Skeleton/MainSectionSkeleton';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';
import { HStack } from '../Stack/HStack';

const MainPageSkeleton = () => {
  return (
    <div>
      <div className="px-4 py-2">
        <HStack spacing={4}>
          <PlaceHolder.Text className="t4-regular rounded-1.5 min-h-10 w-full px-3 py-2">
            서울특별시 관악구
          </PlaceHolder.Text>
          <PlaceHolder.Box className="rounded-1.5 h-10 w-[42px] shrink-0" />
        </HStack>
      </div>
      <MainSectionSkeleton />
    </div>
  );
};

export default MainPageSkeleton;
