import { tv, type VariantProps } from 'tailwind-variants';

const badge = tv({
  base: ['inline-block flex-shrink-0 text-center'],
  variants: {
    type: {
      square: '',
      pill: '',
    },
    size: {
      large: 't2-regular min-h-1.5 px-2 py-1',
      medium: 't1-regular min-h-[19px] px-1.5 py-0.5',
      small: 't1-regular min-h-[18px] px-1 py-0.5',
    },
    property: {
      basic: '',
      primary: '',
      success: '',
      error: '',
      accent: '',
    },
    variant: {
      normal: '',
      fill: '!font-bold',
      outline: 'border !font-bold',
    },
  },
  compoundVariants: [
    // Type + Size combinations
    {
      type: 'square',
      size: 'large',
      class: 'rounded',
    },
    {
      type: 'square',
      size: ['medium', 'small'],
      class: 'rounded-[2px]',
    },
    {
      type: 'pill',
      size: 'large',
      class: 'rounded-xl',
    },
    {
      type: 'pill',
      size: 'medium',
      class: 'rounded-[10px]',
    },
    {
      type: 'pill',
      size: 'small',
      class: 'rounded-full',
    },
    // Property + Variant combinations
    {
      property: 'basic',
      variant: 'normal',
      class: 'bg-palette-gray-200 text-palette-gray-800',
    },
    {
      property: 'basic',
      variant: 'fill',
      class: 'bg-palette-gray-800 text-palette-gray-00',
    },
    {
      property: 'basic',
      variant: 'outline',
      class: 'text-palette-gray-1000 border-palette-gray-1000',
    },
    {
      property: 'primary',
      variant: 'normal',
      class: 'bg-palette-carrot-200 text-fg-brand',
    },
    {
      property: 'primary',
      variant: 'fill',
      class: 'bg-bg-brand-solid text-palette-static-white',
    },
    {
      property: 'primary',
      variant: 'outline',
      class: 'text-fg-brand border-palette-carrot-600',
    },
    {
      property: 'success',
      variant: 'normal',
      class: 'bg-bg-positive-weak text-palette-green-800',
    },
    {
      property: 'success',
      variant: 'fill',
      class: 'bg-bg-positive-solid text-palette-static-white',
    },
    {
      property: 'success',
      variant: 'outline',
      class: 'text-fg-positive border-palette-green-700',
    },
    {
      property: 'error',
      variant: 'normal',
      class: 'bg-bg-critical-weak text-fg-critical',
    },
    {
      property: 'error',
      variant: 'fill',
      class: 'bg-bg-critical-solid text-palette-static-white',
    },
    {
      property: 'error',
      variant: 'outline',
      class: 'text-fg-critical border-palette-red-700',
    },
    {
      property: 'accent',
      variant: 'normal',
      class: 'bg-palette-blue-100 text-palette-blue-900',
    },
    {
      property: 'accent',
      variant: 'fill',
      class: 'bg-palette-blue-600 text-palette-static-white',
    },
    {
      property: 'accent',
      variant: 'outline',
      class: 'text-palette-blue-800 border-palette-blue-800',
    },
  ],
  defaultVariants: {
    type: 'square',
    size: 'large',
    property: 'basic',
    variant: 'normal',
  },
});

export type BadgeProperty = NonNullable<VariantProps<typeof badge>['property']>;
type BadgeProps = VariantProps<typeof badge> & React.HTMLAttributes<HTMLSpanElement>;

const Badge = ({ type, size, property, variant, className, ...props }: BadgeProps) => {
  return <span className={badge({ type, size, property, variant, className })} {...props} />;
};

export default Badge;
