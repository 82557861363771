import ArticleCardStyle from 'components/Article/Card/ArticleCardStyle';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';
import { Stack } from '../Stack/Stack';
import { HStack } from '../Stack/HStack';
import { Spacer } from '@daangn/realty-react/ui';

const ArticleCardSkeleton = () => {
  return (
    <ArticleCardStyle.Container>
      <ArticleCardStyle.Body>
        <PlaceHolder.Box className="rounded-1.5 h-[118px] w-[118px] flex-shrink-0" />
        <ArticleCardStyle.Content>
          <Stack spacing={2}>
            <PlaceHolder.Text>월세 1000</PlaceHolder.Text>
            <Stack spacing={4}>
              <ArticleCardStyle.SubTitle>
                <PlaceHolder.Text>아파트 10평 10층</PlaceHolder.Text>
              </ArticleCardStyle.SubTitle>
              <ArticleCardStyle.Information>
                <PlaceHolder.Text>관리비 없음</PlaceHolder.Text>
              </ArticleCardStyle.Information>
            </Stack>
          </Stack>
          <Stack spacing={4} className="grow">
            <Spacer />
            <HStack>
              <ArticleCardStyle.DateText>
                <PlaceHolder.Text>3일 전</PlaceHolder.Text>
              </ArticleCardStyle.DateText>
              <Spacer />
              <PlaceHolder.Text className="t3-regular">채팅 0 관심 0</PlaceHolder.Text>
            </HStack>
          </Stack>
        </ArticleCardStyle.Content>
      </ArticleCardStyle.Body>
    </ArticleCardStyle.Container>
  );
};

export default ArticleCardSkeleton;
