import { ArticleField, ArticleConfig, ArticleFieldKey } from 'constants/article';
import { format } from 'date-fns';
import { FormArticleType } from 'types/Article';
import { filterNil, truthy } from 'utils/Misc';
import { useArticleForFormFragment_article$data } from '__generated__/useArticleForFormFragment_article.graphql';
import { CreateArticleInputV3 } from '__generated__/useCreateArticle_createArticleMutation.graphql';
import { ArticleStepKeyType } from 'utils/step';
import { EditArticleInputV3 } from '__generated__/useEditArticle_editArticleMutation.graphql';
import type { SafeParseError } from 'zod';
import { 평당_제곱미터 } from '@daangn/realty-sdk';
import { checkIsLandSalesType } from '@daangn/realty-sdk/services';

export const originalArticleToForm = (
  article: useArticleForFormFragment_article$data
): FormArticleType => {
  const { id, ...form } = article;
  const isLandSalesType = checkIsLandSalesType({
    salesType: form.salesTypeV2?.type,
    etcSalesType: form.salesTypeV2?.etcType,
  });

  return {
    ...form,
    ...(isLandSalesType && {
      landArea: form.area,
      landAreaPy: form.area ? (+form.area / 평당_제곱미터).toFixed(0) : undefined,
    }),
    originalArticleId: id,
    etcSalesType: form.salesTypeV2?.type === 'ETC' ? form.salesTypeV2?.etcType : undefined,
    moveInDate: form.moveInDate ? new Date(form.moveInDate) : undefined,
    trades: form.trades.map((trade) => ({
      adjustable: trade.adjustable,
      description: trade.description,
      monthlyPay: trade.monthlyPay ?? trade.yearlyPay,
      preferred: trade.preferred,
      price: trade.price ?? trade.deposit ?? 0,
      tradeType: trade.type,
    })),
    salesType: form.salesTypeV2?.type,
    images: form.images.map((image) => ({
      id: image.originalId,
      thumbnail: image.url,
    })),
    floorPlanImages: form.floorPlanImages.map((image) => ({
      id: image.originalId,
      thumbnail: image.url,
    })),
    videos: form.videos.map((video) => ({
      id: video.originalVideoId,
      filename: video.filename || '업로드된 동영상',
      bigStreamId: video.bigStreamId,
    })),
    py: form.area ? (+form.area / 평당_제곱미터).toFixed(0) : null,
    supplyPy: form.supplyArea ? (+form.supplyArea / 평당_제곱미터).toFixed(0) : undefined,
    originalImageCount: form.images.length,
    includeManageCostOption: form.includeManageCostOptionV2,
    buildingApprovalDate: form.buildingApprovalDate ? new Date(form.buildingApprovalDate) : null,
  };
};

export const formTradesToArticleInputTrades = (trades: FormArticleType['trades']) => {
  return trades.map((trade, i) => {
    return {
      adjustable: trade.adjustable,
      description: trade.description,
      preferred: i === 0 ? true : false,
      // price는 언제든 0이여도 상관 없음
      price: trade.price ?? 0,
      // monthlyPay는 언제든 0이 아니여야함 (값이 있거나 null 이거나)
      monthlyPay: trade.monthlyPay || null,
      tradeType: trade.tradeType,
    };
  });
};

export const formToArticleInput = <T extends FormArticleType>(
  form: T,
  isEdit?: boolean
): CreateArticleInputV3 | EditArticleInputV3 => {
  const {
    address,
    addressInfo,
    fullAddress,
    area,
    supplyArea,
    landArea,
    bathroomCnt,
    content,
    coordinate,
    etcSalesType,
    floor,
    options,
    roomCnt,
    topFloor,
    trades,
    hoianArticleId,
    bizPostId,
    communityArticleOriginalId,
    images,
    floorPlanImages,
    videos,
    moveInDate,
    salesType,
    writerType,
    corRealtyId,
    manageCost,
    manageCostDescription,
    includeManageCostOption,
    excludeManageCostOption,
    premiumMoney,
    premiumMoneyDescription,
    qualitativeItems,
    buildingOrientation,
    buildingApprovalDate,
    contactTargetPhoneNumber,
    buildingName,
    addressDong,
    addressHo,
    buildingUsage,
    etcManageCost,
    isUnknownManageCost,
    availableParkingSpotsV2,
    availableTotalParkingSpots,
    brokerTelephoneNumber,
    landType,
    landPurpose,
  } = form;

  const isLandSalesType = checkIsLandSalesType({
    salesType: salesType,
    etcSalesType,
  });

  return {
    address,
    addressInfo,
    area: isLandSalesType ? landArea : area,
    supplyArea,
    bathroomCnt,
    content,
    coordinate,
    etcSalesType,
    floor,
    options,
    roomCnt,
    topFloor,
    trades: formTradesToArticleInputTrades(trades),
    corRealtyId,
    salesType: salesType || 'ETC',
    ...filterNil({
      bizPostId,
      hoianArticleId,
      communityArticleOriginalId,
    }),
    imageIds: images.map((image) => image.id),
    floorPlanImageIds: floorPlanImages.map((image) => image.id),
    originalVideoIds: videos.map((video) => video.id),
    originalBigStreamIds: videos.map((video) => video.bigStreamId).filter(truthy),
    moveInDate: moveInDate ? format(moveInDate, 'yyyy-MM-dd') : undefined,
    writerType: writerType!,
    manageCost,
    manageCostDescription,
    includeManageCostOption,
    excludeManageCostOption,
    premiumMoney,
    premiumMoneyDescription,
    qualitativeItems,
    buildingOrientation,
    buildingUsage,
    etcManageCost,
    isUnknownManageCost,
    availableParkingSpotsV2,
    availableTotalParkingSpots,
    brokerTelephoneNumber,
    buildingName,
    buildingApprovalDate: buildingApprovalDate
      ? format(buildingApprovalDate, 'yyyy-MM-dd')
      : undefined,
    contactTargetPhoneNumber,
    landType,
    landPurpose,
    ...(!isEdit && { fullAddress, addressDong, addressHo }),
  };
};

export const isEditForm = (formState: FormArticleType) => !!formState.originalArticleId;

export const isDraftable = (
  formState: FormArticleType
): formState is FormArticleType & { stepId: ArticleStepKeyType } => {
  const draftAbleForm =
    formState.writerType === 'BROKER' ? [ArticleFieldKey.SalesType] : [ArticleFieldKey.WriterType];

  return (
    !isEditForm(formState) &&
    !!formState.stepId &&
    draftAbleForm.every((type) => {
      if ('validate' in ArticleConfig[type]) {
        return (ArticleConfig[type] as ArticleField).validate?.(formState)?.success;
      }

      return true;
    })
  );
};

export const isFieldIncluded = (
  form: Pick<FormArticleType, 'writerType' | 'salesType' | 'etcSalesType'>,
  config: ArticleField
) => {
  if ('include' in config && config.include) {
    return config.include?.(form);
  }

  return true;
};

export const validateForm = (formState: FormArticleType) => {
  const isInForm = Object.values(ArticleConfig).filter((config) =>
    isFieldIncluded(formState, config)
  );

  const errorResult = isInForm
    .map((config) => (config as ArticleField).validate?.(formState))
    .filter((result) => result?.success === false)?.[0];

  const parsedError = errorResult as SafeParseError<unknown>;

  return parsedError?.error?.errors?.[0].message;
};
