/**
 * @generated SignedSource<<ecbf956d4729fd43e06036e6d93319ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
import { FragmentRefs } from "relay-runtime";
export type ArticleSummaryInfo_article$data = {
  readonly addressInfo: string;
  readonly area: string | null;
  readonly floor: string | null;
  readonly salesTypeV2: {
    readonly type: SalesTypeEnum;
  };
  readonly supplyArea: string | null;
  readonly topFloor: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCostText_article" | "ArticleSalesTypeText_article">;
  readonly " $fragmentType": "ArticleSummaryInfo_article";
};
export type ArticleSummaryInfo_article$key = {
  readonly " $data"?: ArticleSummaryInfo_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleSummaryInfo_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleSummaryInfo_article"
};

(node as any).hash = "cef1736658990abc5d34d5bfa2adbf5f";

export default node;
