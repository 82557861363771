import type { AppPreloadedContextQuery } from '__generated__/AppPreloadedContextQuery.graphql';
import { createContext, useContext, type PropsWithChildren } from 'react';
import { loadQuery, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import MainEnvironment from 'relay/environment';
import { readViewerBizProfileBroker } from 'utils/bizProfile';
import { readUserIdentification } from 'utils/user';

const InitialAppQuery = graphql`
  query AppPreloadedContextQuery {
    viewer {
      id
      profileImageUrl
      nickname
      originalId
      lastIdentificationVerifyId
      ...bizProfileBroker_viewer
      ...userIdentification_user
    }
  }
`;

const queryReference = loadQuery(MainEnvironment, InitialAppQuery, {});

const AppPreloadedContext = createContext({
  queryReference,
  InitialAppQuery,
});

export const AppPreloadedContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <AppPreloadedContext.Provider value={{ queryReference, InitialAppQuery }}>
      {children}
    </AppPreloadedContext.Provider>
  );
};

export const useAppPreloadedQuery = () => {
  const { queryReference, InitialAppQuery } = useContext(AppPreloadedContext);

  const query = usePreloadedQuery(
    InitialAppQuery,
    queryReference as PreloadedQuery<AppPreloadedContextQuery>
  );

  const bizProfileBroker = readViewerBizProfileBroker(query.viewer);
  const userIdentification = readUserIdentification(query.viewer);

  const verificationInfo = bizProfileBroker?.bizProfile?.verificationInfo;

  return {
    ...query,
    isBizProfileOwnerVerified: !!verificationInfo?.ownerVerified,
    isBizProfileBusinessVerified: !!verificationInfo?.businessVerified,
    bizProfileBroker,
    userIdentification,
  };
};
