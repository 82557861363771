/* eslint-disable @typescript-eslint/ban-types */
import type {
  AccordionDefaultEventParams,
  ArticleItemActionSheetEventType,
  ArticleItemDefaultEventParams,
  ArticlePullupDefaultEventParams,
  BetaActionSheetEventType,
  BottomSheetEventType,
  DialogEventType,
  LoggerContextParams,
  RecentSearchPlaceParam,
  ToastEventType,
  StepDefaultEventParams,
  BrokerHotEventParams,
} from 'logger/types/params';
import type {
  ArticleInvisibleReasonEnum,
  BuildingUsageEnum,
  ErrorMessageKey,
  FeedbackKind,
  ImageQuality,
  ListTopRecommendAgeRangeEnum,
  ListTopRecommendArticleExperimentSegmentEnum,
  ListTopRecommendGenderEnum,
  SalesTypeEnum,
  SmsVerifyCarrierEnum,
  TradedTypeSurveyEnum,
  TradeTypeEnum,
  TradeTypeFilterEnum,
  ViewerWriteFailureReason,
  WriterTypeEnum,
} from 'types/schemaEnums';
import type { ArticleFieldKey } from 'constants/article';
import type { FilterType } from 'constants/filter';
import type { MainBannerServiceType } from 'pages/Main/components/Banner/useMainAdBanner';
import type { EventProviderType, EventType } from 'logger/types/common';
import type { FortuneResultTab } from 'pages/MyRealtyFortuneResult/components/FortuneResult';
import type { FortuneFormInput } from 'pages/MyRealtyFortune/components/FortuneForm';
import type { AreaUnit } from 'types/Article';
import type { RegionSearch } from 'store/regionSearch';
import type { SearchFilter } from 'store/searchFilter';
import type { ArticleCarouselSection } from 'constants/section';
import type { StaticMapType } from 'components/Map/StaticMap';
import type { SalesHistoryStatus } from 'components/SalesHistory/SalesHistory';
import type { SearchRegion } from 'types/Region';
import type { KakaoCategory, KakaoPlaceDocument, SalesTypeFilterEnum } from '@daangn/realty-sdk';
import type { TabType } from 'components/SearchRegion/SearchRegionContainer';
import type { SimpleFormArticleType } from 'types/simpleForm';
import type { AddressNode, CorFormState } from 'hooks/useCheckWritableAddress';
import type { AIConversionMutation$data } from '__generated__/AIConversionMutation.graphql';
import type { CheckListType } from 'pages/ArticleCheckList';
import type { ImageTab } from 'pages/ArticleImages';
import type { NudgeType } from 'store/event';
import type { BizProfileCreateStep } from 'pages/BizProfileCreate';
import type { InteractionState } from '@deck.gl/core/typed/controllers/controller';
import type { TradeToolDataValue, TradeToolType } from 'types/tradeTool';
import type { SalesCategory } from 'constants/category';
import type { GuideTradeType } from 'pages/Guide/constants/type';
import type { UploadStepMutation$data } from '__generated__/UploadStepMutation.graphql';
import type { BrokerPreOpenForm } from 'pages/BrokerPreOpenApplication';
import type { ActionType } from 'pages/MySalesHistory/components/ArticleItemActions';
import type BizProfileReviewForm from 'pages/ReviewNew/components/BizProfileReviewForm';
import type { IdCard } from 'components/Base/IdCardField';
import type { SubscribeForm } from 'store/subscribe';
import type { BizProfileAccountType } from 'hooks/useBizProfileOwnerVerification';
import type { IdentificationType } from 'hooks/useIdentification';
import {
  ArticleFilterInput,
  ArticleLocationFilterInput,
} from '__generated__/useMainSectionMapClusterQuery.graphql';
import type { JusoUnit } from 'components/Base/SearchAddress/Juso/SearchJusoDetailAddress';
import { VerifyResultType } from 'pages/ArticleVerify';

export type LoggerEventType = keyof LoggerEventSchema;
export type LoggerEventParams<T extends LoggerEventType> = LoggerEventSchema[T];

export const DefaultEventProvider: Record<EventType, EventProviderType[]> = {
  click: ['firebase', 'ga4', 'amplitude'],
  impression: ['firebase', 'ga4'],
};

/**
 * EventSchema - 이벤트별 타입을 정의
 */
export type LoggerEventSchema = {
  /* BottomSheet */
  bottom_sheet:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      }
    | {
        event_type: 'watch_biz_profile_articles_sheet';
        biz_profile_id: string;
        article_id: string;
      }
    | {
        event_type: 'biz_profile_account_type_selection';
        biz_profile_id: string;
      };
  click_bottom_sheet_close:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      }
    | {
        event_type: 'watch_biz_profile_articles_sheet';
        biz_profile_id: string;
        article_id: string;
      }
    | {
        event_type: 'biz_profile_account_type_selection';
        biz_profile_id: string;
      };
  click_bottom_sheet_dim:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      }
    | {
        event_type: 'watch_biz_profile_articles_sheet';
        biz_profile_id: string;
        article_id: string;
      }
    | {
        event_type: 'biz_profile_account_type_selection';
        biz_profile_id: string;
      };
  drag_bottom_sheet_handler:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      };
  /* ActionSheet */
  click_action_sheet: {
    event_type: ArticleItemActionSheetEventType;
    article_id: string;
    search_keyword?: string;
  };
  /* BetaActionSheet */
  beta_action_sheet_close: {
    event_type: BetaActionSheetEventType;
  };
  beta_action_sheet_action: {
    event_type: BetaActionSheetEventType;
  };
  /* AlertDialog */
  show_dialog:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  click_dialog_primary_action:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  click_dialog_secondary_action:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  /* ActionToast */
  show_toast: {
    event_type: ToastEventType;
  };
  click_toast_action: {
    event_type: ToastEventType;
  };
  /* ArticleItem */
  show_article_item: ArticleItemDefaultEventParams &
    (
      | {}
      | {
          type: 'main_broker';
          region_2_name: string;
          sales_type: SalesTypeEnum | null;
          trade_type: TradeTypeEnum | null;
        }
    );
  click_article_item: ArticleItemDefaultEventParams &
    (
      | {}
      | {
          type: 'main_broker';
          region_2_name: string;
          sales_type: SalesTypeEnum | null;
          trade_type: TradeTypeEnum | null;
        }
    );
  /* ImpressionLog */
  show_detail_section: {
    article_id: string;
    section: string;
  };
  show_banner_icon:
    | {}
    | {
        event_type: 'article_tool_certification';
      };
  click_banner_icon:
    | {}
    | {
        event_type: 'article_tool_certification';
      };
  show_broker_notice_banner: {
    id: string;
  };
  click_broker_notice_banner: {
    id: string;
  };
  show_broker_faq_banner: {};
  click_broker_faq_banner: {};
  show_main_minimum_realty_carousel: {};
  click_main_minimum_realty_carousel: {
    title: string;
  };
  show_detail_minimum_realty_carousel: {};
  click_detail_minimum_realty_carousel: {
    title: string;
  };
  show_search_region_recommend_list: {
    region: string;
  };
  show_empty_image_button: {
    article_id: string;
    is_my_article: boolean;
  };
  click_request_upload_image: {
    article_id: string;
    is_my_article: boolean;
  };
  click_edit_image: {
    article_id: string;
    is_my_article: boolean;
  };
  show_pullup_bottom_btn_sheet: ArticlePullupDefaultEventParams;
  article_pullup: ArticlePullupDefaultEventParams;
  click_article_update_trades: ArticlePullupDefaultEventParams & {
    is_trade_changed: boolean;
  };
  article_update_trades: ArticlePullupDefaultEventParams;
  click_advertisement_my_article: ArticlePullupDefaultEventParams;
  click_advertisement_no_wait: ArticlePullupDefaultEventParams & {
    is_trade_changed: boolean;
  };
  click_edit_advertisement: ArticlePullupDefaultEventParams;
  click_edit_advertisement_sub: ArticlePullupDefaultEventParams;
  satisfaction_rate_banner_show: {};
  show_main_filter_chip: { type: FilterType };
  click_main_filter_chip: { type: FilterType };
  click_main_reset_filter_button: {};
  list_top_brokers: {
    region_2_name: string;
    sales_type: SalesTypeEnum | null;
    trade_type: TradeTypeEnum | null;
  };
  list_top_recommend: {
    has_advertisement: boolean;
    region_2_name: string | undefined;
    segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
    ageRange?: ListTopRecommendAgeRangeEnum | undefined;
    gender?: ListTopRecommendGenderEnum | undefined;
  };
  click_refresh_recommend: {
    has_advertisement: boolean;
    region_2_name: string | undefined;
    segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
    ageRange?: ListTopRecommendAgeRangeEnum | undefined;
    gender?: ListTopRecommendGenderEnum | undefined;
  };
  show_recent_watched_articles: {};
  show_selected_article_sheet: {};
  show_seller_review_banner: {};
  click_seller_review_banner_title: {};
  click_seller_review_banner_more: {};
  scroll_seller_review_banner: {};
  show_subscribe_chip: {
    is_create: boolean;
    is_edit: boolean;
  };
  click_create_subscribe_chip: {};
  click_edit_subscribe_chip: {};
  show_main_watch_articles: {};
  show_main_ad_banner: {
    service: MainBannerServiceType;
    banner_type: string;
  };
  click_main_ad_banner: {
    service: MainBannerServiceType;
    banner_type: string;
  };
  show_marketing_banner: {
    campaign: string;
  };
  click_marketing_banner: {
    campaign: string;
  };
  suggest_verify: {};
  suggest_request_verify: {};
  suggest_video_upload: {};
  show_direct_deal_filter: {};
  /* Review */
  click_review_confirm_button: {
    send_user_id: string;
    receiver_user_id: string;
    article_id: string;
    is_viewer_sent_review: boolean;
  };
  show_approve_article_button: {
    article_id: string;
    position: string;
    is_pending: boolean;
  };
  show_request_approve_article_button: {
    article_id: string;
    position: string;
    is_pending: boolean;
  };
  show_detail_realty_home_shortcut: {
    article_id: string;
  };
  /* Main */
  show_main_marketing_simple_form_banner: {};
  click_main_marketing_simple_form_banner: {};
  /* BannerCallout */
  click_callout_banner: { event_type: 'tell_us' };
  /* CarouselSection */
  click_more_carousel:
    | {
        event_type: 'similar_articles' | 'recent_watched' | 'main_watch_articles';
      }
    | {
        event_type: 'main_broker';
        region_2_name: string;
        sales_type: SalesTypeEnum | null;
        trade_type: TradeTypeEnum | null;
      }
    | {
        event_type: 'recommend';
        has_advertisement: boolean;
        region_2_name: string | undefined;
        segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
        ageRange?: ListTopRecommendAgeRangeEnum | undefined;
        gender?: ListTopRecommendGenderEnum | undefined;
      }
    | {
        event_type: 'my_realty_fortune';
        tab: FortuneResultTab;
      };
  scroll_carousel:
    | {
        event_type: 'similar_articles' | 'recent_watched' | 'main_watch_articles';
      }
    | {
        event_type: 'main_broker';
        region_2_name: string;
        sales_type: SalesTypeEnum | null;
        trade_type: TradeTypeEnum | null;
      }
    | {
        event_type: 'recommend';
        has_advertisement: boolean;
        region_2_name: string | undefined;
        segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
        ageRange?: ListTopRecommendAgeRangeEnum | undefined;
        gender?: ListTopRecommendGenderEnum | undefined;
      }
    | {
        event_type: 'my_realty_fortune';
        tab: FortuneResultTab;
      };
  /* FloatingTooltip */
  show_tooltip: { event_type: 'broker_banner' | 'verify_banner' | 'sun_section' };
  /* PcUploadBanner */
  click_broker_pc_upload_banner: {} | { event_type: 'sales_type' | 'address' };
  /* Accordion */
  click_accordion: AccordionDefaultEventParams & {
    event_type: 'land_title_bond' | 'land_title_keyword' | 'land_title_small_deposit';
    article_id: string;
  };
  show_detail_fortune_banner: {
    article_id: string;
  };
  click_detail_fortune_banner: {
    article_id: string;
  };
  click_fortune_share_top: {};
  click_fortune_share_button: {};
  click_fortune_form: {};
  click_fortune_realty_more_button: {};
  click_fortune_result_submit_button: FortuneFormInput;
  click_fortune_tab_button: {
    tab: FortuneResultTab;
  };
  click_main_fortune_banner: {};
  show_main_fortune_banner: {};
  show_recap_banner: {};
  click_recap_banner: {};
  show_recap_step: {};
  click_recap_step: {};
  click_recap_realty_button: {};
  click_recap_share_button: {};
  click_recap_more_button: {};
  show_form_area_chip: {
    sales_type: SalesTypeEnum | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    values: { 평: string; 'm²': string }[];
  };
  click_form_area_chip: {
    sales_type: SalesTypeEnum | null;
    value: string | null | undefined;
    area_unit: AreaUnit;
  };
  // 비즈프로필 연결
  click_biz_profile_check: {};
  complete_biz_profile_check: {
    has_biz_profile: boolean;
  };
  click_biz_profile_bottomsheet_create: {};
  click_biz_profile_bottomsheet_connect: {};
  // 메인
  hamburger_menu: {};
  article_new: {
    location: 'main_header' | 'empty_articles';
  };
  sidebar_write_article: {};
  sidebar_my_article: {};
  sidebar_my_like: {};
  sidebar_subscribe: {};
  sidebar_search_article_number: {};
  click_sidebar_broker_guide: {};
  click_sidebar_biz_profile: {};
  click_sidebar_biz_profile_approval: {};
  click_sidebar_biz_profile_manager: {};
  click_sidebar_biz_profile_verification_manage: {
    biz_profile_id: string;
    is_business_verified: boolean;
  };
  sidebar_submenu_faq: {};
  sidebar_submenu_guide: {};
  sidebar_broker: {};
  sidebar_pre_sale: {};
  click_search_realty_onboarding_confirm: {};
  click_search_realty_onboarding_close: {};
  main_list_view: {};
  main_map_view: {};
  main_change_region: {};
  has_article_id_query: { article_id: string };
  click_map_button: {
    is_map_view: boolean;
  };
  click_direct_deal_filter: {};
  cluster_click: {
    zoom_level: number;
  };
  pin_click: {
    zoom_level: number;
  };
  main_current_bound: {
    zoom_level: number;
  };
  main_current_position: {
    zoom_level: number;
  };
  main_subscribe_banner: {
    subscribed: boolean;
  };
  empty_create_subscribe: {
    has_filter: boolean;
  };
  empty_delete_subscribe: {
    has_filter: boolean;
  };
  empty_subscribe: {};
  main_more_articles: {
    page: number;
  };
  main_suggest_parent_region: {};
  main_suggest_parent_region_show: {};
  show_main_article_list: {
    page: number;
    article_count: number;
    all_page: number;
    article_per_page: number;
    rs: Partial<RegionSearch>;
    sf: SearchFilter;
  };
  // 게시글 상세
  article_detail_refresh: {
    article_id: string;
  };
  realty_home_shortcut: {
    article_id: string;
  };
  more_recommend: {
    article_id: string;
  };
  click_broker_rewrite: {
    article_id: string;
  };
  click_article_detail_verify_request_card: {
    article_id: string;
    has_cor_realty_id: boolean;
  };
  click_article_detail_verify_card: {
    article_id: string;
  };
  scroll_article_detail: {
    article_id: string;
    scroll_percentage: number;
  };
  article_report: {
    article_id: string;
  };
  click_detail_biz_profile_recheck: {
    article_id: string;
  };
  video_encoding_fail_edit: {};
  video_encoding_fail_close: {};
  article_image_gallery: {
    article_id: string;
    from?: string;
  };
  article_image_lightbox: {
    article_id: string;
    from?: string;
  };
  play_video: {
    article_id?: string;
    from?: string;
  };
  playtime_video: {
    article_id?: string;
    from?: string;
    playtime: string;
    source?: string;
  };
  article_user: {
    article_id: string;
    target_user_id: number;
  };
  article_status_change: {
    article_id: string;
    prev_status: string;
    next_status: string;
  };
  open_advertisement_create: {
    article_id: string;
  };
  click_recommend_create_advertisement: {
    article_id: string;
    is_hide: boolean;
  };
  click_land_title: {
    article_id: string;
  };
  click_detail_map_type: {
    article_id: string;
    map_type: string;
  };
  article_map: {
    article_id: string;
  };
  click_detail_address_toggle: {
    address_type: string;
  };
  click_detail_address_copy: {
    article_id: string;
    address_type: string;
  };
  click_facility_detail_view: {
    article_id: string;
  };
  click_show_all_traded_result: {
    article_id: string;
  };
  click_detail_more_broker_articles: {
    article_id: string;
    biz_profile_id: string;
  };
  click_broker_reviews_more: {
    article_id: string;
    biz_profile_id: string;
  };
  click_broker_info_section_write_reivew: {
    article_id: string;
  };
  click_report_broker_review: {
    visit_review_id: string;
    biz_profile_id: string;
  };
  click_article_detail_guide: {};
  click_article_detail_broker: {};
  click_approve_article_button: {
    article_id: string;
    position: 'detail_footer' | 'my_article_item_actions';
    is_pending: boolean;
    search_keyword?: string;
  };
  click_request_approve_article_button: {
    article_id: string;
    position: 'detail_footer' | 'my_article_item_actions';
    is_pending: boolean;
    search_keyword?: string;
  };
  article_chat_list: {
    article_id: string;
  };
  click_go_like_article_button: {
    article_id: string;
  };
  click_contact_button: {
    article_id: string;
  };
  click_article_info_button: {
    article_id: string;
  };
  click_edit_phone_number_button: {
    article_id: string;
  };
  show_watch_button_price_down_tooltip: {};
  click_verify_fail_dialog_confirm: {
    article_id: string;
  };
  verify_fail_dialog_faq: {};
  bottom_sheet_suggest_verify_confirm: {
    article_id: string;
  };
  bottom_sheet_suggest_verify_close: {
    article_id: string;
  };
  click_ad_help: {
    article_id: string;
  };
  click_ad_hide: {
    article_id: string;
  };
  click_ad_impression_condition: {
    article_id: string;
  };
  click_carousel_more_item: { section: ArticleCarouselSection };
  article_card_watch_button_like: {
    article_id: string;
    position: string;
  };
  article_card_watch_button_unlike: {
    article_id: string;
    position: string;
  };
  reset_map: {};
  reset_region: {};
  article_broker_link: {};
  article_presale_link: {};
  click_toggle_phone_form: { is_selected: boolean };
  article_form_summary_edit: { step_id: ArticleFieldKey };
  upload_video_guide: {};
  upload_video: {};
  change_image_uploader: {};
  click_change_main_image_guide: {};
  floor_upload_image_guide: {};
  upload_image: {};
  click_image_upload_not_supported_guide: {};
  article_edit_address_cs_center: {};
  image_sort: {};
  search_cor_address_last_input: { keyword: string };
  click_auto_approve_articles_button: { position: 'detail_footer' | 'my_article_item_actions' };
  click_auto_approve_articles_never_show_button: {
    position: 'detail_footer' | 'my_article_item_actions';
  };
  toggle_trade_type: { tradeType: TradeTypeFilterEnum; checked: boolean };
  click_static_map: { map_type: StaticMapType };
  click_sales_history_tab: { status: SalesHistoryStatus };
  search_place_recent: RecentSearchPlaceParam;
  search_region_region: {
    region: Omit<SearchRegion, 'parentRegion'>;
  };
  search_region_kakao_place: {
    place: KakaoPlaceDocument;
  };
  search_region_current_position: {};
  search_region_result: {
    region_id: number;
  };
  search_kakao_place_exist: {};
  search_kakao_place: {
    query: string;
    categories: KakaoCategory[] | undefined;
  };
  search_region_tab_change: {
    tab: TabType;
  };
  search_region_input: {};
  click_search_region_recommend_chip: {
    region: string;
    click_region_chip: string;
  };
  click_has_image: {
    has_image: boolean;
  };
  click_upload_image: {};
  click_save_simple_article_draft_button: {};
  click_create_simple_article_button: {
    article_id: string;
  };
  click_try_create_simple_article_button: {
    is_upload_later: boolean;
  } & SimpleFormArticleType;
  add_other_trade_type: {};
  show_logger_context: LoggerContextParams;
  click_logger_context: LoggerContextParams;
  article_new_close: {};
  iros_maintenance_alarm_done: {};
  submit_address_search_item: CorFormState;
  address_search_item_rental_house: AddressNode;
  address_search_item: AddressNode;
  article_share: {
    article_id: string;
  };
  click_advertisement_create: {
    article_id: string;
    is_advertiser: boolean;
  };
  click_change_advertiser: {};
  click_select_advertiser_complete: {};
  click_ai_conversion_form_confirm_btn: {};
  ai_conversion_fail: {};
  ai_conversion_success: {
    input: string;
    result: AIConversionMutation$data['generateAutoFillFormByPlainTextForBroker'];
    latency: number;
  };
  paste_ai_conversion_form_by_focus: {};
  click_ai_conversion_info: {};
  click_notification_setting: {
    notification_type: 'RECOMMEND' | 'WATCH_FOMO';
    is_on: boolean;
  };
  click_seller_review_text_more: {};
  article_checklist_memo: {
    memo: string;
    article_id: string;
  };
  article_checklist_item: {
    title: string;
    type: CheckListType;
    article_id: string;
  };
  article_checklist_image_change: {
    images: string[];
    article_id: string;
  };
  checklist_edit_image: {};
  checklist_edit_image_done: {
    images: string[];
  };
  click_compare_edit_button: {};
  click_compare_edit_all_select_btn: { label: 'all_remove' | 'all_select' };
  click_compare_edit_confirm_btn: { articleCount: number };
  click_article_detail_more_hide: {
    article_id: string;
    is_hide: boolean;
  };
  click_article_detail_more_pullup: {
    article_id: string;
  };
  click_article_detail_more_edit: {
    article_id: string;
  };
  click_article_detail_more_delete: {
    article_id: string;
  };
  actually_called_event_only_ios: {
    article_id: string;
  };
  click_call_button: {
    article_id: string;
  };
  click_subscribe_section_create: {
    region: SearchRegion;
    tradeType: TradeTypeFilterEnum;
    salesType: SalesTypeFilterEnum;
  };
  click_subscribe_section_delete: {
    subscribe_id: string | undefined;
  };
  click_load_sun_section: {
    article_id: string;
  };
  click_detail_anchor_tab: {
    article_id: string;
  };
  show_detail_anchor_tab: {
    article_id: string;
  };
  media_slide_change: {
    articleId: string | undefined;
    current_index: number;
    current_media_type: 'image' | 'video';
  };
  chat_button: {
    isSuccess: boolean;
    article_id: string;
  };
  click_facility_detail_confirm: {
    article_id: string;
  };
  trade_survey_select_platform: {
    platform: TradedTypeSurveyEnum | null;
  };
  trade_survey_other_platform: {
    other_platform: string;
  };
  set_view_recommend_notification: {
    salesType: SalesTypeEnum;
    region2Id: [string, number] | undefined;
  };
  article_update: {
    isSuccess: boolean;
    article_id: string;
  };
  article_edit_close: {};
  click_edit_article_image: {};
  article_images_tab_scroll: { article_id: string };
  click_article_images_tab: { article_id: string; tab: ImageTab };
  click_see_land_title_certificate: { article_id: string };
  click_land_title_iros_link: {
    article_id: string;
  };
  click_new_land_title: {
    article_id: string;
  };
  click_land_title_helpful_feedback: {
    is_helpful: boolean;
  };
  land_title_certificate_share: {};
  show_static_map: { type: StaticMapType; zoom: number };
  show_step: StepDefaultEventParams &
    (
      | {
          event_type: 'article_new';
          writer_type: WriterTypeEnum | null | undefined;
          sales_type: SalesTypeEnum | null | undefined;
          is_biz_profile_broker: boolean;
        }
      | {
          event_type: 'article_step_edit';
        }
    );
  show_nudge: {
    type: NudgeType;
  };
  biz_profile_create_step: {
    step: BizProfileCreateStep;
  };
  ai_conversion_bottom_sheet_close: {};
  click_ai_conversion_sales_type: {};
  ai_conversion_bottom_sheet_confirm: {};
  click_summary_image_change_banner: {};
  article_create: {
    isSuccess: boolean;
    hoian_article_id: string | null | undefined;
    article_id: string | null;
    first_image_quality?: ImageQuality | undefined;
    has_changed_on_bad_image?: boolean | undefined;
  };
  article_new_verify_confirm: {};
  article_new_verify_cancel: {};
  click_seller_reviews_callout: {};
  pullup_refresh: {};
  complete_request_verify: { article_id: string; phone_number: string; type: string };
  click_request_article_verify: { article_id: string; phone_number: string };
  click_skip_request_article_verify: { article_id: string };
  click_request_article_verify_close: { article_id: string };
  click_request_article_verify_fail_invite: { article_id: string };
  click_request_article_verify_fail_close: { article_id: string };
  click_request_article_verify_success_confirm: { article_id: string };
  article_roadview_loaded: {
    pano_id: string | null;
  };
  click_sunlight_nav_back: {};
  click_sun_section_time_change: { article_id: string; hour: number };
  click_sun_section_season_change: { article_id: string; month: string };
  interact_sun_section: { article_id: string } & InteractionState;
  click_back_to_article_detail_sun_section: {};
  click_select_buyer: { article_id: string; user_id: string };
  click_traded_other_platform: {
    article_id: string;
  };
  article_trade_tool_item: {
    type: TradeToolType | 'checklist';
  };
  article_trade_tool_chat: { article_id: string };
  article_trade_tool_detail_data_change: {
    article_id: string;
    type: TradeToolType;
    data: TradeToolDataValue;
  };
  article_trade_tool_detail_guide: {
    salesType: SalesCategory;
    tradeType: GuideTradeType;
    step: '1' | '2' | '3' | '4';
  };
  check_article_verify: {
    type: VerifyResultType;
    article_id: string;
  };
  click_biz_profile_approval_setting_auto_approve_articles_switch: {
    on: boolean;
  };
  click_biz_profile_approval_setting_confirm_button: {};
  click_biz_profile_approval_setting_change_owner_button: {};
  click_biz_profile_create_biz_info: {};
  click_biz_profile_create_biz_guide: {};
  click_biz_profile_complete_submit: {};
  click_biz_profile_complete_retry: {};
  click_biz_profile_complete_image_retry: {};
  click_biz_profile_upload_next: {};
  complete_biz_profile_upload: {
    type: UploadStepMutation$data['parseRegistrationImage']['__typename'];
  };
  click_biz_profile_upload_fail_sheet_retry: {};
  click_biz_profile_upload_faq: {};
  click_broker_onboarding_write: {};
  click_broker_onboarding_help_main: {};
  click_broker_onboarding_help_faq: {};
  click_broker_onboarding_copy_pc_link: {};
  click_apply_broker_preopen_application: {
    form: BrokerPreOpenForm;
  };
  complete_apply_broker_preopen_application: { form: BrokerPreOpenForm };
  faq_link_click: {
    type: string;
  };
  reset_filter: {};
  filter_submit: SearchFilter;
  main_filter_change: {
    article_count: number | undefined;
  } & SearchFilter;
  filter_subscribe_banner_create: SearchFilter;
  filter_subscribe_banner_delete: SearchFilter;
  guide_share: { tradeType: GuideTradeType; salesType: SalesCategory };
  guide_download: { href: string };
  guide_index: {};
  guide_quiz: {};
  guide_review: { answer: 'yes' | 'no' };
  satisfaction_rate_banner_complete: {
    rating: number;
    feedback: string;
  };
  click_search_article_number_search: {
    article_id: string;
  };
  main_map_bound_change: ArticleFilterInput;
  click_satisfaction_survey_star: {};
  click_satisfaction_survey_submit: {
    starRating: number;
    feedbackComment: string;
    feedbackKind: FeedbackKind | undefined;
    relatedOriginalArticleId: string | undefined;
  };
  submit_satisfaction_survey: {
    starRating: number;
    feedbackComment: string;
    feedbackKind: FeedbackKind | undefined;
    relatedOriginalArticleId: string | undefined;
  };
  click_marketing_price_search: {};
  click_marketing_price_search_share: {};
  click_marketing_price_search_result_share: {};
  click_marketing_price_search_result_write: {};
  click_marketing_price_search_result_more_in_realty: {};
  click_marketing_price_search_result_more_in_realty_banner: {};
  click_marketing_price_search_result_trade_type: {};
  click_marketing_price_search_result_sales_type: {
    sales_type: SalesTypeFilterEnum;
  };
  click_my_page_compare_button: {};
  click_sales_history_article_action: {
    article_id: string;
    action: ActionType;
    search_keyword?: string;
  };
  create_broker_review: {
    review_form: BizProfileReviewForm;
    article_id: string;
  };
  click_search_address_result: {
    address: string;
    query: string;
  };
  university_search_item: {
    university: KakaoPlaceDocument;
  };
  click_upload_my_article: {
    from_write: boolean;
  };
  scroll_seller_review_list: {
    percentage: string;
  };
  sms_identification_send: {
    user_name: string;
    phone: string;
    telecom: SmsVerifyCarrierEnum | '';
    id_card: IdCard;
  };
  sms_identification_terms_link: {
    url: string;
  };
  sms_identification_resend: {};
  toggle_subscribe: {};
  subscribe_detail_add: {
    region_id: string | undefined;
  };
  subscribe_detail_edit: {};
  subscribe_detail_delete: {};
  click_subscribe_onboarding_submit: {};
  subscribe_setting_reset: {};
  subscribe_setting_submit: SubscribeForm;
  subscribe_add_region: {};
  trade_review_done: { article_id: string; review_id: string };
  trade_review_not_review: {
    article_id: string;
  };
  click_review_new_change_buyer_button: {
    article_id: string;
  };
  click_select_buyer_change_buyer_button: {
    article_id: string;
    user_id: string;
  };
  click_close: {
    user_interacted: boolean;
  };
  reset_year_trade: {};
  load_kakao_map_script: {};
  show_promotion_share: {};
  check_promotion: { from: 'me' | 'other' };
  click_promotion_send: {};
  show_advertisement_bar_chart: {};
  show_form_broker_application_notice: {};
  click_form_broker_application_notice: {};
  click_broker_bad_review_sender_callout: {};
  click_broker_bad_review_receiver_callout: {};
  show_broker_bad_review_sender_callout: {};
  show_broker_bad_review_receiver_callout: {};
  click_detail_biz_profile_review_rating: {
    article_id: string;
    biz_profile_id: string;
  };
  click_watch_biz_profile_articles: {
    article_id: string;
    biz_profile_id: string;
  };
  click_watch_biz_profile_articles_next: {
    article_id: string;
    biz_profile_id: string;
  };
  click_detail_commission_toggle: {
    article_id: string;
    is_open: boolean;
  };
  submit_visit_review: {
    article_id: string;
    review_id: string;
  };
  fetch_building_ledger: {
    address: string;
    area: number | null;
    buildingUsage: BuildingUsageEnum | null;
    buildingApprovalDate: string | null;
    elevator: number | null;
    floor: number | null;
    topFloor: number | null;
  };
  show_carousel_marketing_banner: {};
  click_detail_biz_profile_invite: {
    article_id: string;
  };
  click_biz_profile_owner_verification_failed_contact: {
    biz_profile_id: string;
  };
  click_biz_profile_owner_verification_failed_write_article: {
    biz_profile_id: string;
  };
  click_sidebar_biz_profile_owner_verification: {
    biz_profile_id: string;
  };
  click_biz_profile_owner_verification: {
    biz_profile_id: string;
  };
  click_biz_profile_account_type_select_confirm: {
    biz_profile_id: string;
    account_type: BizProfileAccountType;
  };
  biz_profile_owner_verification_identification_failed: {
    biz_profile_id: string;
  };
  biz_profile_owner_verification_success: {
    biz_profile_id: string;
  };
  biz_profile_owner_verification_failed: {
    biz_profile_id: string;
  };
  biz_profile_owner_verification_skip: {
    biz_profile_id: string;
  };
  biz_profile_create_success: {
    biz_profile_id: string;
  };
  biz_profile_connect_success: {
    biz_profile_id: string;
  };
  biz_profile_connect_failed: {
    message_key: ErrorMessageKey | null;
  };
  start_user_identification: {
    type: IdentificationType;
  };
  close_user_identification: {
    type: IdentificationType;
  };
  user_identification_failed: {
    type: IdentificationType;
  };
  user_identification_success: {
    type: IdentificationType;
  };
  user_identification_move_agreement: {
    type: IdentificationType;
  };
  click_article_detail_minimum_realty: {};
  sidebar_submenu_minimum_realty: {};
  click_sales_history_search_field: {};
  overlay_click: {
    zoom_level: number;
    bounds: kakao.maps.LatLngBounds;
    center: { lat: string; lon: string };
    count: number;
  };
  main_map_bound_change_none: { type: 'point' | 'cluster' } & ArticleFilterInput;
  main_map_cluster_no_dense_boundary: {
    input: ArticleLocationFilterInput & ArticleLocationFilterInput;
    result: {
      center: { lat: string; lon: string };
      count: number;
      region: { id: string; depth: number; name: string };
    }[];
  };
  main_map_cluster_none: {
    input: ArticleLocationFilterInput & ArticleLocationFilterInput;
    counts: number;
  };
  show_broker_guide_banner: {};
  click_broker_guide_banner: {};
  click_change_cor_address_item: {
    article_id: string;
    form: CorFormState;
  };
  click_change_cor_address_edit: {};
  click_change_cor_address_faq: {};
  click_change_cor_address_confirm: {};
  change_cor_address_success: {
    article_id: string;
    form: CorFormState;
  };
  change_cor_address_failed: {
    article_id: string;
    form: CorFormState;
  };
  click_biz_profile_connect_fail_edit: {
    biz_profile_id: string;
  };
  click_biz_profile_connect_fail_contact: {
    biz_profile_id: string;
  };
  click_review_claim_button: { article_id: string };
  show_verify_request_banner: { article_id: string };
  click_verify_request_banner: { article_id: string };
  show_verify_fail_banner: { article_id: string };
  click_verify_fail_banner: { article_id: string };
  show_invisible_banner: {
    reason: ArticleInvisibleReasonEnum;
  };
  click_invisible_banner_faq_button: { reason: ArticleInvisibleReasonEnum };
  show_hide_callout: { article_id: string };
  click_hide_callout_unhide_button: { article_id: string };
  show_image_request_callout: { article_id: string; is_hide: boolean };
  click_image_request_callout_upload_button: { article_id: string; is_hide: boolean };
  click_map_current_position: {};
  click_map_find_path: {};
  click_broker_hot_articles_write: BrokerHotEventParams;
  click_broker_hot_articles_guide: BrokerHotEventParams;
  show_cor_address_form: {};
  show_search_juso_find_cor_address: {
    input: string;
  };
  show_search_juso_unit_manual_input: {
    input: string;
  };
  click_search_juso_find_cor_address: {
    input: string;
  };
  click_search_juso_unit_manual_input: {
    input: string;
  };
  click_search_juso_unit_select: {
    unit: JusoUnit;
  };
  click_main_area_mode_filter: {
    area_unit: AreaUnit;
  };
};

export type EventProviderMapping<T extends LoggerEventType> = LoggerEventSchema[T] extends {
  event_type: string;
}
  ? Record<LoggerEventSchema[T]['event_type'], EventProviderType[]>
  : never;

/**
 * EventProperties - 이벤트별 값을 정의
 */
export const LoggerEventProperties: {
  [K in keyof LoggerEventSchema]: {
    description: string;
    provider?: EventProviderType[];
    type: EventType;
    providerMapping?: Partial<EventProviderMapping<K>>;
  };
} = {
  /* BottomSheet */
  bottom_sheet: {
    description: '공통 바텀시트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'posthog'],
      biz_profile_connect: ['firebase', 'ga4', 'amplitude'],
      biz_profile_invitation_onboarding: ['firebase', 'ga4', 'amplitude'],
      biz_profile_image_verification_fail: ['firebase', 'ga4', 'amplitude'],
      biz_profile_account_type_selection: ['firebase', 'ga4', 'amplitude'],
    },
  },
  click_bottom_sheet_close: {
    description: '공통 바텀시트 닫기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'posthog'],
    },
  },
  click_bottom_sheet_dim: {
    description: '공통 바텀시트 dim 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'posthog'],
    },
  },
  drag_bottom_sheet_handler: {
    description: '공통 바텀시트 핸들러 드래그',
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'posthog'],
    },
  },
  /* ActionSheet */
  click_action_sheet: {
    description: '액션시트 클릭',
    type: 'click',
  },
  /* BetaActionSheet */
  beta_action_sheet_close: {
    description: '베타 액션시트 닫기 클릭',
    type: 'click',
  },
  beta_action_sheet_action: {
    description: '베타 액션시트 클릭',
    type: 'click',
  },
  /* AlertDialog */
  show_dialog: {
    description: '공통 다이얼로그 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
    providerMapping: {
      biz_profile_invalid_brn: ['firebase', 'ga4', 'amplitude'],
      biz_profile_duplicated_brn: ['firebase', 'ga4', 'amplitude'],
      biz_profile_create_block_leave: ['firebase', 'ga4', 'amplitude'],
    },
  },
  click_dialog_primary_action: {
    description: '공통 다이얼로그 Primary 버튼 클릭',
    type: 'click',
  },
  click_dialog_secondary_action: {
    description: '공통 다이얼로그 Secondary 버튼 클릭',
    type: 'click',
  },
  /* ActionToast */
  show_toast: {
    description: '공통 토스트 노출',
    type: 'impression',
  },
  click_toast_action: {
    description: '공통 토스트 액션 클릭',
    type: 'click',
  },
  /* ArticleItem */
  show_article_item: {
    description: '아티클 아이템 노출',
    type: 'impression',
  },
  click_article_item: {
    description: '아티클 아이템 클릭',
    type: 'click',
  },
  /* ImpressionLog */
  show_detail_section: {
    description: '아티클 상세 섹션 노출',
    type: 'impression',
  },
  show_banner_icon: {
    description: '공통 배너 아이콘 노출',
    type: 'impression',
  },
  click_banner_icon: {
    description: '공통 배너 아이콘 클릭',
    type: 'click',
  },
  show_broker_notice_banner: {
    description: '메인 > 중개사용 공지사항 배너 노출',
    type: 'impression',
  },
  click_broker_notice_banner: {
    description: '메인 > 중개사용 공지사항 배너 클릭',
    type: 'click',
  },
  show_broker_faq_banner: {
    description: '메인 > 중개사용 FAQ 배너 노출',
    type: 'impression',
  },
  click_broker_faq_banner: {
    description: '메인 > 중개사용 FAQ 배너 클릭',
    type: 'click',
  },
  show_main_minimum_realty_carousel: {
    description: '메인 > 최소한의 부동산 정보 캐로셀 노출',
    type: 'impression',
  },
  click_main_minimum_realty_carousel: {
    description: '메인 > 최소한의 부동산 정보 캐로셀 클릭',
    type: 'click',
  },
  show_detail_minimum_realty_carousel: {
    description: '상세 > 최소한의 부동산 정보 캐로셀 노출',
    type: 'impression',
  },
  click_detail_minimum_realty_carousel: {
    description: '상세 > 최소한의 부동산 정보 캐로셀 클릭',
    type: 'click',
  },
  show_search_region_recommend_list: {
    description: '지역 검색 페이지 > region과 함께 자주 찾는 곳 목록이 보였을 때',
    type: 'impression',
  },
  show_empty_image_button: {
    description: '상세 > 사진 올리기/업로드 버튼 노출',
    type: 'impression',
  },
  click_request_upload_image: {
    description: '상세 > 사진 올리기 요청 클릭',
    type: 'click',
  },
  click_edit_image: {
    description: '상세 > 사진 올리기 클릭',
    type: 'click',
  },
  show_pullup_bottom_btn_sheet: {
    description: '끌어올리기 > 하단 버튼 시트 노출',
    type: 'impression',
  },
  article_pullup: {
    description: '끌어올리기 > 끌어올리기 버튼 클릭',
    type: 'click',
  },
  click_article_update_trades: {
    description: '끌어올리기 > 매물 타입 변경 클릭',
    type: 'click',
  },
  article_update_trades: {
    description: '끌어올리기 > 매물 타입 변경',
    type: 'click',
  },
  click_advertisement_my_article: {
    description: '끌어올리기 > 내 매물 맞춤 광고하기 클릭',
    type: 'click',
  },
  click_advertisement_no_wait: {
    description: '끌어올리기 > 기다리지 않고 광고하기 버튼 클릭',
    type: 'click',
  },
  click_edit_advertisement: {
    description: '끌어올리기 > 광고 수정하기 버튼 클릭',
    type: 'click',
  },
  click_edit_advertisement_sub: {
    description: '끌어올리기 > 광고 수정하기 서브 버튼 클릭',
    type: 'click',
  },
  satisfaction_rate_banner_show: {
    description: '메인 > 만족도 조사 배너 노출',
    type: 'impression',
  },
  show_main_filter_chip: {
    description: '메인 > 필터칩 노출',
    type: 'impression',
  },
  click_main_filter_chip: {
    description: '메인 > 필터칩 클릭',
    type: 'click',
  },
  click_main_reset_filter_button: {
    description: '메인 > 필터칩 초기화 클릭',
    type: 'click',
  },
  list_top_brokers: {
    description: '메인 > 최상단 중개사 매물 섹션 노출',
    type: 'impression',
  },
  list_top_recommend: {
    description: '메인 > 최상단 추천매물 섹션 노출',
    type: 'impression',
  },
  click_refresh_recommend: {
    description: '메인 > 최상단 추천매물 섹션 > 새로고침 클릭',
    type: 'click',
  },
  show_recent_watched_articles: {
    description: '메인 > 최근 본 매물 섹션 노출',
    type: 'impression',
  },
  show_selected_article_sheet: {
    description: '메인 > 지도탭 아티클 시트 노출',
    type: 'impression',
  },
  show_seller_review_banner: {
    description: '메인 > 판매자 리뷰 배너 노출',
    type: 'impression',
  },
  click_seller_review_banner_title: {
    description: '메인 > 판매자 리뷰 배너 타이틀 클릭',
    type: 'click',
  },
  click_seller_review_banner_more: {
    description: '메인 > 판매자 리뷰 배너 더보기 클릭',
    type: 'click',
  },
  scroll_seller_review_banner: {
    description: '메인 > 판매자 리뷰 배너 스크롤',
    type: 'click',
  },
  show_subscribe_chip: {
    description: '메인 > 새글알림 Chip 노출',
    type: 'impression',
  },
  click_create_subscribe_chip: {
    description: '메인 > 새글알림 생성 chip 클릭',
    type: 'click',
  },
  click_edit_subscribe_chip: {
    description: '메인 > 새글알림 수정 chip 클릭',
    type: 'click',
  },
  show_main_watch_articles: {
    description: '메인 > 관심목록 캐로셀 노출',
    type: 'impression',
  },
  show_main_ad_banner: {
    description: '메인 > 광고 배너 노출',
    type: 'impression',
  },
  click_main_ad_banner: {
    description: '메인 > 광고 배너 클릭',
    type: 'click',
  },
  show_marketing_banner: {
    description: '메인 > 마케팅 배너 노출',
    type: 'impression',
  },
  click_marketing_banner: {
    description: '메인 > 마케팅 배너 클릭',
    type: 'click',
  },
  suggest_verify: {
    description: '상세 > 집주인 인증 유도 섹션 노출',
    type: 'impression',
  },
  suggest_request_verify: {
    description: '상세 > 집주인 인증 요청 섹션 노출',
    type: 'impression',
  },
  suggest_video_upload: {
    description: '상세 > 비디오 업로드 유도 섹션 노출',
    type: 'impression',
  },
  show_direct_deal_filter: {
    description: '메인 > 직거래 매물만 보기 필터 노출',
    type: 'impression',
  },
  /* Review */
  click_review_confirm_button: {
    description: '리뷰 > 확인 버튼 클릭',
    type: 'click',
  },
  show_approve_article_button: {
    description: '상세 > 매물 등록 승인하기 버튼 노출',
    type: 'impression',
  },
  show_request_approve_article_button: {
    description: '상세 > 매물 등록 승인하기 버튼 노출',
    type: 'impression',
  },
  show_detail_realty_home_shortcut: {
    description: '상세 > 직거래 바로가기 노출',
    type: 'impression',
  },
  show_main_marketing_simple_form_banner: {
    description: '마케팅 매물 작성 배너 임프레션',
    type: 'impression',
  },
  click_main_marketing_simple_form_banner: {
    description: '마케팅 매물 작성 배너 클릭',
    type: 'click',
  },
  click_callout_banner: {
    description: '콜아웃 배너 클릭',
    type: 'click',
  },
  click_more_carousel: {
    description: '캐로셀 섹션 더보기 클릭',
    type: 'click',
  },
  scroll_carousel: {
    description: '캐로셀 섹션 스크롤',
    type: 'click',
  },
  show_tooltip: {
    description: 'floating tooltip 노출 공통 이벤트',
    type: 'impression',
  },
  click_broker_pc_upload_banner: {
    description: 'PC 버전 업로드 배너 클릭한 이벤트예요.',
    type: 'click',
  },
  click_accordion: {
    description: '아코디언 클릭',
    type: 'click',
  },
  show_detail_fortune_banner: {
    description: '상세 > 운세 배너 노출',
    type: 'impression',
  },
  click_detail_fortune_banner: {
    description: '상세 > 운세 배너 클릭',
    type: 'click',
  },
  click_fortune_share_top: {
    description: '운세 > 공유 탑 클릭',
    type: 'click',
  },
  click_fortune_share_button: {
    description: '운세 > 공유 버튼 클릭',
    type: 'click',
  },
  click_fortune_realty_more_button: {
    description: '운세 > 매물 더보기 버튼 클릭',
    type: 'click',
  },
  click_fortune_form: {
    description: '운세 > 폼 클릭',
    type: 'click',
  },
  click_fortune_result_submit_button: {
    description: '운세 > 결과 제출 버튼 클릭',
    type: 'click',
  },
  click_fortune_tab_button: {
    description: '운세 > 탭 버튼 클릭',
    type: 'click',
  },
  show_recap_banner: {
    description: '메인 > 2024 연말정산 배너 노출',
    type: 'impression',
  },
  click_recap_banner: {
    description: '메인 > 2024 연말정산 배너 클릭',
    type: 'click',
  },
  show_recap_step: {
    description: '2024 연말정산 스텝 노출',
    type: 'impression',
  },
  click_recap_step: {
    description: '2024 연말정산 스텝 이동',
    type: 'click',
  },
  click_recap_realty_button: {
    description: '2024 연말정산 결과 > 당근부동산 구경하기 버튼 클릭',
    type: 'click',
  },
  click_recap_share_button: {
    description: '2024 연말정산 결과 > 공유하기 버튼 클릭',
    type: 'click',
  },
  click_recap_more_button: {
    description: '2024 연말정산 결과 > 추천 게시글 더보기 버튼 클릭',
    type: 'click',
  },
  show_form_area_chip: {
    description: '폼 > 전용면적 칩 노출',
    type: 'impression',
  },
  click_form_area_chip: {
    description: '폼 > 전용면적 칩 클릭 (value: undefined - 해제, null - 확인이 필요해요)',
    type: 'click',
  },
  click_biz_profile_check: {
    description: '공통 > 비즈프로필 존재 여부 체크 클릭',
    type: 'click',
  },
  complete_biz_profile_check: {
    description: '공통 > 비즈프로필 존재 여부 체크 완료',
    type: 'click',
  },
  click_biz_profile_bottomsheet_create: {
    description: '공통 > 비즈프로필 연결 바텀시트 > 생성 클릭',
    type: 'click',
  },
  click_biz_profile_bottomsheet_connect: {
    description: '공통 > 비즈프로필 연결 바텀시트 > 연결 클릭',
    type: 'click',
  },
  hamburger_menu: {
    description: '메인 > 햄버거 메뉴 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_new: {
    description: '글쓰기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_write_article: {
    description: '메인 > 사이드바 > 글쓰기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_my_article: {
    description: '메인 > 사이드바 > 내 매물 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_my_like: {
    description: '메인 > 사이드바 > 내 관심목록 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_subscribe: {
    description: '메인 > 사이드바 > 알림 관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_search_article_number: {
    description: '메인 > 사이드바 > 매물번호 검색 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile: {
    description: '메인 > 사이드바 > 운영자 추가/관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile_approval: {
    description: '메인 > 사이드바 > 운영자 승인 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile_manager: {
    description: '메인 > 사이드바 > 운영자 관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_broker_guide: {
    description: '메인 > 사이드바 > 중개소 활용 가이드 클릭',
    type: 'click',
  },
  sidebar_submenu_faq: {
    description: '메인 > 사이드바 > FAQ 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_submenu_guide: {
    description: '메인 > 사이드바 > 가이드 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_broker: {
    description: '메인 > 사이드바 > 중개사 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_pre_sale: {
    description: '메인 > 사이드바 > 분양 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_search_realty_onboarding_confirm: {
    description: '메인 > 매물번호 검색 온보딩 확인버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_search_realty_onboarding_close: {
    description: '메인 > 매물번호 검색 온보딩 닫기버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_list_view: {
    description: '메인 > 리스트 뷰 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_map_view: {
    description: '메인 > 지도 뷰 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  has_article_id_query: {
    description: '메인 > url의 article_id로 게시물 조회',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  main_change_region: {
    description: '메인 > 지역 변경 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_map_button: {
    description: '메인 > 지도 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_direct_deal_filter: {
    description: '메인 > 직거래 매물만 보기 필터 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  cluster_click: {
    description: '메인 > 지도 클러스터 클릭 (카카오 클러스터링)',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  pin_click: {
    description: '메인 > 지도 핀 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_current_bound: {
    description: '메인 > 현재 지역 바운드 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_current_position: {
    description: '메인 > 현재 위치 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_subscribe_banner: {
    description: '메인 > 새글알림 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  empty_create_subscribe: {
    description: '메인 > 매물 없을 때 알림 생성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  empty_delete_subscribe: {
    description: '메인 > 매물 없을 때 알림 삭제 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  empty_subscribe: {
    description: '메인 > 매물 없을 때 알림 유도 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  main_more_articles: {
    description: '메인 > 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_suggest_parent_region: {
    description: '메인 > 지역 추천 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_suggest_parent_region_show: {
    description: '메인 > 지역 추천 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_main_article_list: {
    description: '메인 > 매물 리스트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_main_fortune_banner: {
    description: '메인 > 운세 배너 클릭',
    type: 'click',
  },
  show_main_fortune_banner: {
    description: '메인 > 운세 배너 노출',
    type: 'impression',
  },
  // 상세 페이지
  article_detail_refresh: {
    description: '상세 > Pull to Refresh 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  realty_home_shortcut: {
    description: '상세 > 부동산 바로가기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  more_recommend: {
    description: '상세 > 추천 게시글 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_rewrite: {
    description: '상세 > 중개사 글 다시쓰기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_detail_verify_request_card: {
    description: '상세 > 집주인 인증 요청 카드 > 30초 만에 인증 요청하기 클릭',
    type: 'click',
  },
  click_article_detail_verify_card: {
    description: '상세 > 집주인 인증 카드 > 30초 만에 인증하기 클릭',
    type: 'click',
  },
  scroll_article_detail: {
    description: '상세 > 스크롤 퍼센트',
    type: 'click',
  },
  article_report: {
    description: '상세 > 신고하기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_biz_profile_recheck: {
    description: '상세 > 비즈프로필 재검수 요청 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  video_encoding_fail_edit: {
    description: '상세 > 비디오 인코딩 실패 > 다시 시도 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  video_encoding_fail_close: {
    description: '상세 > 비디오 인코딩 실패 > 닫기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_image_gallery: {
    description: '상세 > 이미지 갤러리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_image_lightbox: {
    description: '상세 > 이미지 라이트박스 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  play_video: {
    description: '상세 > 비디오 재생',
    type: 'click',
  },
  playtime_video: {
    description: '상세 > 비디오 재생 시간',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  article_user: {
    description: '상세 > 유저 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_status_change: {
    description: '상세 > 매물 상태 변경',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  open_advertisement_create: {
    description: '상세 > 광고 생성 페이지 진입',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_recommend_create_advertisement: {
    description: '상세 > 광고 생성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_land_title: {
    description: '상세 > 등기부등본 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_map_type: {
    description: '상세 > 지도 타입 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_map: {
    description: '상세 > 지도 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_address_toggle: {
    description: '상세 > 주소 타입 토글 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_address_copy: {
    description: '상세 > 주소 복사 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_facility_detail_view: {
    description: '상세 > 편의시설 상세 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_show_all_traded_result: {
    description: '상세 > 실거래가 거래 결과 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_more_broker_articles: {
    description: '상세 > 중개사 게시글 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_reviews_more: {
    description: '상세 > 중개사 리뷰 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_info_section_write_reivew: {
    description: '상세 > 중개사 리뷰 작성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_report_broker_review: {
    description: '상세 > 중개사 리뷰 신고 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_detail_guide: {
    description: '상세 > 가이드 확인하기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_detail_broker: {
    description: '상세 > 우리동네 중개사 찾아보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_approve_article_button: {
    description: '상세 > 중개사 게시글 승인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_request_approve_article_button: {
    description: '상세 > 중개사 게시글 승인 요청 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_chat_list: {
    description: '상세 > 채팅 목록보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_go_like_article_button: {
    description: '상세 > 게시글 관심 후 토스트에서 나의 관심목록 보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_contact_button: {
    description: '상세 > 문의하기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_info_button: {
    description: '상세 > 게시글 정보 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_phone_number_button: {
    description: '상세 > 연락처 수정 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_watch_button_price_down_tooltip: {
    description: '상세 > 관심목록 추가 툴팁 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_verify_fail_dialog_confirm: {
    description: '상세 > 집주인 인증 실패 다이얼로그 확인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  verify_fail_dialog_faq: {
    description: '상세 > 집주인 인증 실패 다이얼로그 FAQ 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  bottom_sheet_suggest_verify_confirm: {
    description: '상세 > 집주인 인증 유도 바텀시트 확인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  bottom_sheet_suggest_verify_close: {
    description: '상세 > 집주인 인증 유도 바텀시트 닫기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_ad_help: {
    description: '아티클 광고 버튼 > 광고 도움말 버튼 클릭',
    type: 'click',
  },
  click_ad_hide: {
    description: '아티클 광고 버튼 > 광고 숨기기 버튼 클릭',
    type: 'click',
  },
  click_ad_impression_condition: {
    description: '아티클 광고 버튼 > 광고 게시글 노출 기준 버튼 클릭',
    type: 'click',
  },
  click_carousel_more_item: {
    description: '캐로셀 섹션 > 더보기 버튼 클릭',
    type: 'click',
  },
  article_card_watch_button_like: {
    description: '관심 버튼 > 매물 관심 클릭',
    type: 'click',
  },
  article_card_watch_button_unlike: {
    description: '관심 버튼 > 매물 관심 해제',
    type: 'click',
  },
  reset_map: {
    description: '매물이 없어요 > 필터 초기화 클릭',
    type: 'click',
  },
  reset_region: {
    description: '매물이 없어요 > 지역 초기화 클릭',
    type: 'click',
  },
  article_broker_link: {
    description: '폼 > 중개사 광고 > 공인중개사 전용 글쓰기 클릭',
    type: 'click',
  },
  article_presale_link: {
    description: '폼 > 중개사 광고 > 신규 분양 광고하기 클릭',
    type: 'click',
  },
  click_toggle_phone_form: {
    description: '폼 > 요약 > 전화문의 받기 토글 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  article_form_summary_edit: {
    description: '폼 > 요약 > 각 항목 수정 클릭',
    type: 'click',
  },
  upload_video_guide: {
    description: '폼 > 영상 업로드 > 비디오 업로드 클릭 (가이드 바텀시트 오픈)',
    type: 'click',
  },
  upload_video: {
    description: '폼 > 영상 업로드 > 매물 소개 영상 바텀시트 > 소개 영상 업로드 클릭',
    type: 'click',
  },
  change_image_uploader: {
    description: '폼 > 사진 업로드 > 업로드 이미지 변경',
    type: 'click',
  },
  click_change_main_image_guide: {
    description: '폼 > 사진 업로드 > 대표 사진 변경 가이드 클릭',
    type: 'click',
  },
  floor_upload_image_guide: {
    description: '폼 > 사진 업로드 > 평면도 이미지 가이드 바텀시트 > 평면도 사진 올리기 클릭',
    type: 'click',
  },
  upload_image: {
    description: '폼 > 사진 업로드 > 매물 소개 사진 바텀시트 > 매물 사진 올리기 클릭',
    type: 'click',
  },
  click_image_upload_not_supported_guide: {
    description: '사진 업로드 > 이미지 업로드 가이드 클릭', // [TODO] description 체크
    type: 'click',
  },
  article_edit_address_cs_center: {
    description: '아티클 수정 > 주소 수정 고객센터 링크 클릭',
    type: 'click',
  },
  image_sort: {
    description: '이미지 > 이미지 정렬 수정',
    type: 'click',
  },
  search_cor_address_last_input: {
    description: '등기소 주소검색 마지막 키워드',
    type: 'click',
  },
  click_auto_approve_articles_button: {
    description: '간편승인 바텀시트 > 간편승인 버튼 클릭',
    type: 'click',
  },
  click_auto_approve_articles_never_show_button: {
    description: '간편승인 바텀시트 > 간편승인 다시 안 보기 클릭',
    type: 'click',
  },
  toggle_trade_type: {
    description: '필터 > 거래 유형 토글 클릭',
    type: 'click',
  },
  click_static_map: {
    description: '지도 > static map 클릭',
    type: 'click',
  },
  click_sales_history_tab: {
    description: '판매내역 > 거래상태 칩(탭) 클릭',
    type: 'click',
  },
  search_place_recent: {
    description: '지역 검색 > 최근 검색어 클릭',
    type: 'click',
  },
  search_region_region: { description: '지역 검색 > region 클릭', type: 'click' },
  search_region_kakao_place: { description: '지역 검색 > kakao place 클릭', type: 'click' },
  search_region_current_position: {
    description: '지역 검색 > 현 위치 근처 매물보기 버튼 클릭',
    type: 'click',
  },
  click_search_region_recommend_chip: {
    description: '지역 검색 > 추천 지역 목록 칩 클릭',
    type: 'click',
  },
  search_region_result: {
    description: '지역 검색 > 검색 결과 중 하나의 주소 클릭',
    type: 'click',
  },
  search_kakao_place: {
    description: '지역 검색 > 건물/학교 검색',
    type: 'click',
  },
  search_kakao_place_exist: {
    description: '지역 검색 > 카카오 장소 검색 결과가 존재하는지 확인',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  search_region_tab_change: {
    description: '지역 검색 > 지역검색 tab 변경',
    type: 'click',
  },
  search_region_input: {
    description: '지역 검색 > 검색창 클릭',
    type: 'click',
  },
  click_has_image: {
    description: '간단폼 > 사진이 있어요 / 없어요 클릭',
    type: 'click',
  },
  click_upload_image: {
    description: '간단폼 > 사진 업로드 클릭',
    type: 'click',
  },
  click_save_simple_article_draft_button: {
    description: '간단폼 > 임시저장 클릭',
    type: 'click',
  },
  click_create_simple_article_button: {
    description: '간단폼 > 아티클 생성 클릭',
    type: 'click',
  },
  click_try_create_simple_article_button: {
    description: '간단폼 > 간단 글쓰기 작성 시도',
    type: 'click',
  },
  add_other_trade_type: {
    description: '거래 폼 > 다른 거래방식 추가',
    type: 'click',
  },
  show_logger_context: {
    description: 'logger context 노출',
    type: 'impression',
  },
  click_logger_context: {
    description: 'logger context 클릭',
    type: 'click',
  },
  article_new_close: {
    description: '앱바 > 닫기 클릭',
    type: 'click',
  },
  iros_maintenance_alarm_done: {
    description: 'iros 알림받기 신청 완료',
    type: 'click',
  },
  submit_address_search_item: {
    description: '주소 검색 결과 > 아이템 클릭 후 확인 버튼 클릭',
    type: 'click',
  },
  address_search_item_rental_house: {
    description: '주소 검색 결과 > 임대주택 선택',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  address_search_item: {
    description: '주소 검색 결과 > 아이템 클릭',
    type: 'click',
  },
  article_share: {
    description: '공유하기 클릭',
    type: 'click',
  },
  click_advertisement_create: {
    description: '광고 생성 > 광고 생성 버튼 클릭',
    type: 'click',
  },
  click_change_advertiser: {
    description: '광고계정 선택 > 광고계정 변경 클릭',
    type: 'click',
  },
  click_select_advertiser_complete: {
    description: '광고계정 선택 > 광고계정 선택 완료 버튼 클릭',
    type: 'click',
  },
  click_ai_conversion_form_confirm_btn: {
    description: 'AI 매물정보 폼 > 확인 버튼 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  ai_conversion_fail: {
    description: 'AI 매물정보 폼 > 자동입력 실패',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  ai_conversion_success: {
    description: 'AI 매물정보 폼 > 자동입력 성공',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  paste_ai_conversion_form_by_focus: {
    description: 'AI 매물정보 폼 > 포커스로 AI 매물정보 폼에 paste',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_ai_conversion_info: {
    description: 'AI 매물정보 폼 > 자동입력 안내 클릭',
    type: 'click',
  },
  click_notification_setting: {
    description: '알림 설정 > 알림 설정 변경',
    type: 'click',
  },
  click_seller_review_text_more: {
    description: '판매자 리뷰 > 긴 텍스트 (더보기) 클릭',
    type: 'click',
  },
  article_checklist_memo: {
    description: '체크리스트 >  메모 변경',
    type: 'click',
  },
  article_checklist_item: {
    description: '체크리스트 > 항목 클릭',
    type: 'click',
  },
  article_checklist_image_change: {
    description: '체크리스트 > 이미지 변경',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  checklist_edit_image: {
    description: '체크리스트 > 사진 수정 버튼 클릭',
    type: 'click',
  },
  checklist_edit_image_done: {
    description: '체크리스트 > 사진 수정 완료 버튼 클릭',
    type: 'click',
  },
  click_compare_edit_button: {
    description: '비교하기 > 수정 버튼 클릭',
    type: 'click',
  },
  click_compare_edit_all_select_btn: {
    description: '비교하기 수정 바텀시트 > 전체 선택/해제 버튼 클릭',
    type: 'click',
  },
  click_compare_edit_confirm_btn: {
    description: '비교하기 수정 바텀시트 > 확인 버튼 클릭',
    type: 'click',
  },
  click_article_detail_more_hide: {
    description: '아티클 상세 > 비공개 on/off 클릭',
    type: 'click',
  },
  click_article_detail_more_delete: {
    description: '아티클 상세 > 삭제 버튼 클릭',
    type: 'click',
  },
  click_article_detail_more_pullup: {
    description: '아티클 상세 > 끌어올리기 클릭',
    type: 'click',
  },
  click_article_detail_more_edit: {
    description: '아티클 상세 > 게시글 수정 클릭',
    type: 'click',
  },
  actually_called_event_only_ios: {
    description: 'iOS에서 실제로 전화한 경우',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_call_button: {
    description: '전화문의 버튼 클릭',
    type: 'click',
  },
  click_subscribe_section_create: {
    description: '아티클 상세 > 알림 설정 섹션 버튼 클릭',
    type: 'click',
  },
  click_subscribe_section_delete: {
    description: '아티클 상세 > 알림 설정 섹션 삭제 버튼 클릭',
    type: 'click',
  },
  click_load_sun_section: {
    description: '아티클 상세 > 3D로 매물보기 클릭',
    type: 'click',
  },
  click_detail_anchor_tab: {
    description: '아티클 상세 > 앵커 탭 클릭',
    type: 'click',
  },
  show_detail_anchor_tab: {
    description: '아티클 상세 > 앵커 탭 노출',
    type: 'impression',
  },
  media_slide_change: {
    description: '아티클 상세 > 미디어 슬라이드 변경',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  chat_button: {
    description: '아티클 상세 > 채팅 버튼 클릭',
    type: 'click',
  },
  click_facility_detail_confirm: {
    description: '아티클 상세 > 편의시설 바텀시트 > 확인 했어요 클릭',
    type: 'click',
  },
  trade_survey_select_platform: {
    description: '아티클 상세 > 거래 완료 모달 > 거래완료 설문 플랫폼 선택',
    type: 'click',
  },
  trade_survey_other_platform: {
    description: '아티클 상세 > 거래 완료 모달 > 다른 서비스 입력',
    type: 'click',
  },
  set_view_recommend_notification: {
    description: '아티클 상세 > 매물 추천 조건 성립하여 API 호출',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  article_update: {
    description: '아티클 수정 > 수정 성공/실패',
    type: 'click',
  },
  article_edit_close: {
    description: '아티클 수정 > 닫기 버튼 클릭',
    type: 'click',
  },
  click_edit_article_image: {
    description: '아티클 이미지 수정 > 클릭',
    type: 'click',
  },
  article_images_tab_scroll: {
    description: '아티클 이미지 > 이미지 갤러리에서 동일 탭을 클릭하여 스크롤',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  click_article_images_tab: {
    description: '아티클 이미지 >  이미지 갤러리 탭 클릭',
    type: 'click',
  },
  click_see_land_title_certificate: {
    description: '등기부등본 간편분석 > 등기부등본 원본 보기 클릭',
    type: 'click',
  },
  click_land_title_iros_link: {
    description: '등기부등본 간편분석 > 인터넷등기소발급 클릭',
    type: 'click',
  },
  click_new_land_title: {
    description: '등기부등본 간편분석 > 계약 직전 등기부등본 발급하기 버튼 클릭',
    type: 'click',
  },
  click_land_title_helpful_feedback: {
    description: '등기부등본 간편분석 > 간편 분석이 도움이 되었나요? 응답 버튼 클릭',
    type: 'click',
  },
  land_title_certificate_share: {
    description: '등기부등본 간편분석 pdf > 파일 내보내기 클릭',
    type: 'click',
  },
  show_static_map: {
    description: '공통 > Static 지도 노출',
    type: 'impression',
  },
  show_step: {
    description: '글쓰기/수정 > Step 노출',
    type: 'impression',
    providerMapping: {
      article_new: ['firebase', 'ga4', 'amplitude', 'posthog'],
    },
  },
  show_nudge: {
    description: '공통 > 넛지 노출',
    type: 'impression',
  },
  biz_profile_create_step: {
    description: '비즈프로필 생성 > 생성 Step 노출',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'impression',
  },
  ai_conversion_bottom_sheet_close: {
    description: 'AI 매물정보 바텀시트 > 바텀시트 닫기 또는 직접 입력할게요 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_ai_conversion_sales_type: {
    description: '폼 > 간편 매물 등록 버튼 클릭',
    type: 'click',
  },
  ai_conversion_bottom_sheet_confirm: {
    description: 'AI 매물정보 바텀시트 > 옮겨쓰기 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_summary_image_change_banner: {
    description: '아티클 생성 > 요약 > 대표 사진 변경 배너 클릭',
    type: 'click',
  },
  article_create: {
    description: '아티클 생성 > 요약 > 게시글 성공/실패',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  article_new_verify_confirm: {
    description: '아티클 생성 > 집주인 인증 바텀시트 > 인증하기 버튼 클릭',
    type: 'click',
  },
  article_new_verify_cancel: {
    description: '아티클 생성 > 집주인 인증 바텀시트 > 다음에 할게요 클릭',
    type: 'click',
  },
  click_seller_reviews_callout: {
    description: '간단폼 > 판매자 후기 보기 콜아웃 클릭',
    type: 'click',
  },
  pullup_refresh: {
    description: '끌어올리기 > pull to refresh 새로고침',
    type: 'click',
  },
  complete_request_verify: {
    description: '집주인 인증 요청 > 집주인 인증 요청 완료',
    type: 'click',
  },
  click_request_article_verify: {
    description: '집주인 인증 요청 > 집주인 인증 요청 버튼 클릭',
    type: 'click',
  },
  click_skip_request_article_verify: {
    description: '(deprecated) 집주인 인증 요청 > 집주인 인증 요청 건너뛰기 버튼 클릭',
    type: 'click',
  },
  click_request_article_verify_close: {
    description: '집주인 인증 요청 > 집주인 인증 요청 닫기 버튼 클릭',
    type: 'click',
  },
  click_request_article_verify_fail_invite: {
    description: '집주인 인증 요청 > 인증 요청 실패 > 초대하기 버튼 클릭',
    type: 'click',
  },
  click_request_article_verify_fail_close: {
    description: '집주인 인증 요청 > 인증 요청 실패 > 닫기 버튼 클릭',
    type: 'click',
  },
  click_request_article_verify_success_confirm: {
    description: '집주인 인증 요청 > 인증 요청 성공 > 확인 버튼 클릭',
    type: 'click',
  },
  article_roadview_loaded: {
    description: '로드뷰 > 페이지 로드 완료',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  click_sunlight_nav_back: {
    description: '3D 지도 > nav 백버튼 클릭',
    type: 'click',
  },
  click_sun_section_time_change: {
    description: '3D 지도 > 일조량 시간 변경',
    type: 'click',
  },
  click_sun_section_season_change: {
    description: '3D 지도 > 일조량 계절 변경',
    type: 'click',
  },
  interact_sun_section: {
    description: '3D 지도 > 일조량 드래그',
    type: 'click',
  },
  click_back_to_article_detail_sun_section: {
    description: '3D 지도 > 뒤로가기 버튼 클릭 (상세페이지로 돌아가기)',
    type: 'click',
  },
  click_select_buyer: {
    description: '구매자 선택 > 구매자 선택 클릭',
    type: 'click',
  },
  click_traded_other_platform: {
    description: '구매자 선택 > 다른 곳에서 거래했어요 클릭',
    type: 'click',
  },
  article_trade_tool_item: {
    description: '거래도구 > 거래도구 아이템 클릭',
    type: 'click',
  },
  article_trade_tool_chat: {
    description: '거래도구 > 채팅하기 클릭',
    type: 'click',
  },
  article_trade_tool_detail_data_change: {
    description: '거래도구 상세 > 데이터 변경',
    type: 'click',
  },
  article_trade_tool_detail_guide: {
    description: '거래도구 상세 > 가이드 버튼 클릭',
    type: 'click',
  },
  check_article_verify: {
    description: '집주인 인증 > 집주인 인증 체크',
    type: 'click',
  },
  click_biz_profile_approval_setting_auto_approve_articles_switch: {
    description: '매물간편승인관리 > 자동승인스위치 클릭',
    type: 'click',
  },
  click_biz_profile_approval_setting_confirm_button: {
    description: '매물간편승인관리 > 확인 버튼 클릭',
    type: 'click',
  },
  click_biz_profile_approval_setting_change_owner_button: {
    description: '매물간편승인관리 > 소유자변경 버튼 클릭',
    type: 'click',
  },
  click_biz_profile_create_biz_info: {
    description: '비즈 프로필 생성 > 비즈프로필이 궁금해요 클릭',
    type: 'click',
  },
  click_biz_profile_create_biz_guide: {
    description: '비즈 프로필 생성 > 당근부동산 활용 방법 클릭',
    type: 'click',
  },
  click_biz_profile_complete_submit: {
    description: '비즈 프로필 생성 > 생성 완료 버튼 클릭',
    type: 'click',
  },
  click_biz_profile_complete_retry: {
    description: '비즈 프로필 생성 > 입력 정보 재등록 클릭',
    type: 'click',
  },
  click_biz_profile_complete_image_retry: {
    description: '비즈 프로필 생성 > 사진 재등록 클릭',
    type: 'click',
  },
  click_biz_profile_upload_next: {
    description: '비즈 프로필 생성 > 다음 버튼 클릭',
    type: 'click',
  },
  complete_biz_profile_upload: {
    description: '비즈 프로필 생성 > 이미지 인증 완료',
    type: 'click',
  },
  click_biz_profile_upload_fail_sheet_retry: {
    description: '비즈 프로필 생성 > 사진 재등록 클릭',
    type: 'click',
  },
  click_biz_profile_upload_faq: {
    description: '비즈 프로필 생성 > 문의하기 클릭',
    type: 'click',
  },
  click_broker_onboarding_write: {
    description: '중개사 온보딩 > 글쓰기 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude'],
  },
  click_broker_onboarding_help_main: {
    description: '중개사 온보딩 > 당근부동산 바로가기 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_broker_onboarding_help_faq: {
    description: '중개사 온보딩 > 고객센터 문의하러 가기 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_broker_onboarding_copy_pc_link: {
    description: '중개사 온보딩 > PC 버전 링크 복사하기 클릭',
    type: 'click',
    provider: ['firebase', 'ga4', 'amplitude', 'posthog'],
  },
  click_apply_broker_preopen_application: {
    description: '중개사 사전오픈 > 지원하기 버튼 클릭',
    type: 'click',
  },
  complete_apply_broker_preopen_application: {
    description: '중개사 사전오픈 > 지원하기 완료',
    type: 'click',
  },
  faq_link_click: {
    description: 'FAQ > 개선제안하기/문의하기 링크 클릭',
    type: 'click',
  },
  reset_filter: {
    description: '필터 > 필터 재설정',
    type: 'click',
  },
  filter_submit: {
    description: '필터 > 필터 적용',
    type: 'click',
  },
  main_filter_change: {
    description: '필터 > 변경된 필터에 맞게 게시글을 새로 가져올 때 발생',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  filter_subscribe_banner_create: {
    description: '필터 알림 설정 유도 배너 > 알림 생성',
    type: 'click',
  },
  filter_subscribe_banner_delete: {
    description: '필터 알림 설정 유도 배너 > 알림 삭제',
    type: 'click',
  },
  guide_share: {
    description: '가이드 > 공유하기 클릭',
    type: 'click',
  },
  guide_download: {
    description: '가이드 > 표준계약서 다운받기 클릭',
    type: 'click',
  },
  guide_index: {
    description: '가이드 > 목차 인덱스 클릭',
    type: 'click',
  },
  guide_quiz: {
    description: '가이드 > 퀴즈 응답 버튼 클릭',
    type: 'click',
  },
  guide_review: {
    description: "가이드 > '가이드가 도움이 되셨나요?' 응답 버튼 클릭",
    type: 'click',
  },
  satisfaction_rate_banner_complete: {
    description: '만족도 조사 바텀시트 > 만족도 조사 완료',
    type: 'click',
  },
  click_search_article_number_search: {
    description: '매물번호 검색 바텀시트 > 매물번호 검색',
    type: 'click',
  },
  main_map_bound_change: {
    description: '메인 > 지도의 범위가 달라졌을 때 그에 맞는 게시글을 가져오는데 성공한 경우',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  click_satisfaction_survey_star: {
    description: '만족도 조사 > 별점 클릭',
    type: 'click',
  },
  click_satisfaction_survey_submit: {
    description: '만족도 조사 > 완료 버튼 클릭',
    type: 'click',
  },
  submit_satisfaction_survey: {
    description: '만족도 조사 > 만족도 조사 완료',
    type: 'click',
  },
  click_marketing_price_search: {
    description: '마케팅 동네 시세 > 마케팅 페이지(검색 버튼) 클릭',
    type: 'click',
  },
  click_marketing_price_search_share: {
    description: '마케팅 동네 시세 > 공유 클릭',
    type: 'click',
  },
  click_marketing_price_search_result_share: {
    description: '마케팅 동네 시세결과 > 공유 클릭',
    type: 'click',
  },
  click_marketing_price_search_result_write: {
    description: '마케팅 동네 시세결과 > 내놓기 클릭',
    type: 'click',
  },
  click_marketing_price_search_result_more_in_realty: {
    description: '마케팅 동네 시세결과 > 당근부동산 둘러보기 클릭 (결과가 없어요)',
    type: 'click',
  },
  click_marketing_price_search_result_more_in_realty_banner: {
    description: '마케팅 동네 시세결과 > 당근부동산 둘러보기 클릭',
    type: 'click',
  },
  click_marketing_price_search_result_trade_type: {
    description: '마케팅 동네 시세결과 > 거래 유형 선택',
    type: 'click',
  },
  click_marketing_price_search_result_sales_type: {
    description: '마케팅 동네 시세결과 > 매물 유형 선택',
    type: 'click',
  },
  click_my_page_compare_button: {
    description: '나의 부동산 >  비교하기 버튼 클릭',
    type: 'click',
  },
  click_sales_history_article_action: {
    description: '나의 부동산 > 판매내역 > 아이템 액션',
    type: 'click',
  },
  create_broker_review: {
    description: '중개사 후기 작성 > 후기 생성',
    type: 'click',
  },
  click_search_address_result: {
    description: '주소 검색 > 검색 결과 클릭',
    type: 'click',
  },
  university_search_item: {
    description: '대학 검색 > 아이템 클릭',
    type: 'click',
  },
  click_upload_my_article: {
    description: '판매 리뷰 > 내 매물 올리기 버튼 클릭',
    type: 'click',
  },
  scroll_seller_review_list: {
    description: '판매 리뷰 > 리스트 스크롤 비율',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  sms_identification_send: {
    description: 'sms 인증 > sms 인증 요청 (다시인증 제외)',
    type: 'click',
  },
  sms_identification_terms_link: {
    description: 'sms 인증 > 이용약관 시트 > sms 인증 이용약관 링크 클릭',
    type: 'click',
  },
  sms_identification_resend: {
    description: 'sms 인증 > sms 인증 다시보내기 버튼 클릭',
    type: 'click',
  },
  toggle_subscribe: {
    description: '알림 관리 > 알림 on/off 토글',
    type: 'click',
  },
  subscribe_detail_add: {
    description: '알림 관리 > 알림 추가',
    type: 'click',
  },
  subscribe_detail_edit: {
    description: '알림 관리 > 알림 수정',
    type: 'click',
  },
  subscribe_detail_delete: {
    description: '알림 관리 > 알림 삭제',
    type: 'click',
  },
  click_subscribe_onboarding_submit: {
    description: '알림 유도 > 알림 신청 버튼 클릭',
    type: 'click',
  },
  subscribe_setting_reset: {
    description: '알림 설정 > 알림 초기화',
    type: 'click',
  },
  subscribe_setting_submit: {
    description: '알림 설정 > 알림 생성 완료',
    type: 'click',
  },
  subscribe_add_region: {
    description: '알림 설정 > 동네 추가 클릭',
    type: 'click',
  },
  trade_review_done: {
    description: '거래 완료 후기 > 작성 완료',
    type: 'click',
  },
  trade_review_not_review: {
    description: '거래 완료 후기 > 후기를 남기고 싶지 않아요 클릭',
    type: 'click',
  },
  click_review_new_change_buyer_button: {
    description: '거래 완료 후기 > 거래한 이웃 변경 버튼 클릭',
    type: 'click',
  },
  click_select_buyer_change_buyer_button: {
    description: '구매자 선택 > 거래한 이웃 변경 버튼 클릭',
    type: 'click',
  },
  click_close: {
    description: 'X 버튼 클릭',
    type: 'click',
  },
  reset_year_trade: {
    description: '지역 검색 > 지역 변경으로 인해 연세 필터 초기화',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  load_kakao_map_script: {
    description: '카카오맵 스크립트 로드',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  show_promotion_share: {
    description: '메인 > 부동산 레퍼럴 프로모션 > 공유하기 노출',
    type: 'impression',
  },
  check_promotion: {
    description:
      '메인 > 부동산 레퍼럴 프로모션 > 링크 확인 (me: 내가 공유한 링크예요/other: 응모 랜드킷으로 이동)',
    type: 'click',
  },
  click_promotion_send: {
    description: '메인 > 부동산 레퍼럴 프로모션 > 나도 선물 보내기 버튼 클릭',
    type: 'click',
  },
  show_advertisement_bar_chart: {
    description: '상세 > 광고 유도 카드 노출 (변경된 바 차트 UI)',
    type: 'impression',
  },
  show_form_broker_application_notice: {
    description: '글쓰기 > 공인중개사 지원 신청 안내 노출',
    type: 'impression',
  },
  click_form_broker_application_notice: {
    description: '글쓰기 > 공인중개사 지원 신청하기 클릭',
    type: 'click',
  },
  show_broker_bad_review_sender_callout: {
    description: '후기 > 건강한 거래 문화를 만들어요 발송자 콜아웃 노출',
    type: 'impression',
  },
  show_broker_bad_review_receiver_callout: {
    description: '후기 > 건강한 거래 문화를 만들어요 수신자 콜아웃 노출',
    type: 'impression',
  },
  click_broker_bad_review_sender_callout: {
    description: '후기 > 건강한 거래 문화를 만들어요 발송자 콜아웃 클릭',
    type: 'click',
  },
  click_broker_bad_review_receiver_callout: {
    description: '후기 > 건강한 거래 문화를 만들어요 수신자 콜아웃 클릭',
    type: 'click',
  },
  click_detail_biz_profile_review_rating: {
    description: '상세 > 상단 중개소 후기 클릭',
    type: 'click',
  },
  click_watch_biz_profile_articles: {
    description: '상세 > 중개소 매물 모아보기 유도 바텀시트 > 매물 모아보기 버튼 클릭',
    type: 'click',
  },
  click_watch_biz_profile_articles_next: {
    description: '상세 > 중개소 매물 모아보기 유도 바텀시트 > 다음에 할게요 버튼 클릭',
    type: 'click',
  },
  click_detail_commission_toggle: {
    description: '상세 > 직거래로 아끼는 비용 토글 클릭',
    type: 'click',
  },
  submit_visit_review: {
    description: '후기 > 후기 생성 완료',
    type: 'click',
  },
  fetch_building_ledger: {
    description: '건축물 대장 매칭 정보',
    type: 'click',
    provider: ['firebase'],
  },
  show_carousel_marketing_banner: {
    description: '상세 > 캐로셀 마케팅 배너 노출',
    type: 'impression',
  },
  click_detail_biz_profile_invite: {
    description: '상세 > 비즈프로필 초대 클릭',
    type: 'click',
  },
  click_biz_profile_owner_verification_failed_contact: {
    description: '비즈프로필 > 소유자 인증 실패 문의하기 클릭',
    type: 'click',
  },
  click_biz_profile_owner_verification_failed_write_article: {
    description: '비즈프로필 > 소유자 인증 실패 글쓰기 클릭',
    type: 'click',
  },
  click_sidebar_biz_profile_owner_verification: {
    description: '사이드바 > 비즈프로필 소유자 인증 클릭',
    type: 'click',
  },
  click_biz_profile_owner_verification: {
    description: '비즈프로필 > 소유자 인증 클릭',
    type: 'click',
  },
  click_biz_profile_account_type_select_confirm: {
    description: '비즈프로필 > 계정 유형 선택 확인 클릭',
    type: 'click',
  },
  biz_profile_owner_verification_identification_failed: {
    description: '비즈프로필 > 소유자 인증 본인 확인 실패',
    type: 'click',
  },
  biz_profile_owner_verification_skip: {
    description: '비즈프로필 > 소유자 인증 건너뛰기 클릭',
    type: 'click',
  },
  biz_profile_owner_verification_success: {
    description: '비즈프로필 > 소유자 인증 성공',
    type: 'click',
  },
  biz_profile_owner_verification_failed: {
    description: '비즈프로필 > 소유자 인증 실패',
    type: 'click',
  },
  start_user_identification: {
    description: '사용자 본인 인증 시작',
    type: 'impression',
  },
  close_user_identification: {
    description: '사용자 본인 인증 닫기',
    type: 'click',
  },
  user_identification_failed: {
    description: '사용자 본인 인증 실패',
    type: 'click',
  },
  user_identification_success: {
    description: '사용자 본인 인증 성공',
    type: 'click',
  },
  user_identification_move_agreement: {
    description: '사용자 본인 인증 페이 약관 있는 경우',
    type: 'click',
  },
  click_article_detail_minimum_realty: {
    description: '상세 > 미리보면 좋은 부동산 정보 클릭',
    type: 'click',
  },
  sidebar_submenu_minimum_realty: {
    description: '메인 > 사이드바 > 최소한의 부동산 클릭',
    type: 'click',
  },
  click_sales_history_search_field: {
    description: '판매내역 > 주소 검색 필드 클릭',
    type: 'click',
  },
  overlay_click: {
    description: '메인 > 지도 오버레이 클릭 (서버 클러스터링)',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_map_bound_change_none: {
    description: '메인 > 지도의 범위가 달라졌을 때 그에 맞는 게시글을 가져오는데 결과가 없는 경우',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  main_map_cluster_no_dense_boundary: {
    description: '메인 > 클러스터 조회 시 밀집 지역 정보가 없는 경우',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  main_map_cluster_none: {
    description: '메인 > 클러스터 조회 결과가 없는 경우',
    type: 'click',
    provider: ['firebase', 'ga4'],
  },
  biz_profile_create_success: {
    description: '비즈프로필 생성 성공',
    type: 'click',
  },
  biz_profile_connect_success: {
    description: '비즈프로필 연결 성공',
    type: 'click',
  },
  biz_profile_connect_failed: {
    description: '비즈프로필 연결 실패',
    type: 'click',
  },
  show_broker_guide_banner: {
    description: '아티클 생성 > 작성자 선택 > 중개사 온보딩 안내 노출',
    type: 'impression',
  },
  click_broker_guide_banner: {
    description: '아티클 생성 > 작성자 선택 > 중개사 온보딩 안내 > 가이드 보기 클릭',
    type: 'click',
  },
  click_change_cor_address_item: {
    description: '집주인인증 주소변경 > 주소 선택',
    type: 'click',
  },
  click_change_cor_address_edit: {
    description: '집주인인증 주소변경 > 수정하기',
    type: 'click',
  },
  click_change_cor_address_faq: {
    description: '집주인인증 주소변경 > FAQ',
    type: 'click',
  },
  click_change_cor_address_confirm: {
    description: '집주인인증 주소변경 > 확인',
    type: 'click',
  },
  change_cor_address_success: {
    description: '집주인인증 주소변경 > 주소 변경 성공',
    type: 'click',
  },
  change_cor_address_failed: {
    description: '집주인인증 주소변경 > 주소 변경 실패',
    type: 'click',
  },
  click_biz_profile_connect_fail_edit: {
    description: '비즈프로필 연동 실패 > 토스트 > 수정하기 클릭',
    type: 'click',
  },
  click_biz_profile_connect_fail_contact: {
    description: '비즈프로필 연동 실패 > 토스트 > 문의하기 클릭',
    type: 'click',
  },
  click_review_claim_button: {
    description: '후기 > 이의신청하기 버튼 클릭',
    type: 'click',
  },
  show_verify_request_banner: {
    description: '상세 > (하단) 집주인 인증 요청 배너 노출',
    type: 'impression',
  },
  click_verify_request_banner: {
    description: '상세 > (하단) 집주인 인증 요청 배너 클릭',
    type: 'click',
  },
  show_verify_fail_banner: {
    description: '상세 > (하단) 집주인 인증 실패 배너 노출',
    type: 'impression',
  },
  click_verify_fail_banner: {
    description: '상세 > (하단) 집주인 인증 실패 배너 클릭',
    type: 'click',
  },
  show_invisible_banner: {
    description: '상세 > (상단) 미노출 배너 노출 (invisibleReason)',
    type: 'impression',
  },
  click_invisible_banner_faq_button: {
    description: '상세 > (상단) 미노출 배너 > 운영정책 확인하기 클릭',
    type: 'click',
  },
  show_hide_callout: {
    description: '상세 > (상단) 숨김 해제 배너 노출',
    type: 'impression',
  },
  click_hide_callout_unhide_button: {
    description: '상세 > (상단) 숨김 해제 배너 > 숨김 해제 클릭',
    type: 'click',
  },
  show_image_request_callout: {
    description: '상세 > (상단) 사진 요청 배너 노출',
    type: 'impression',
  },
  click_image_request_callout_upload_button: {
    description: '상세 > (상단) 사진 요청 배너 > 사진 올리기 클릭',
    type: 'click',
  },
  click_sidebar_biz_profile_verification_manage: {
    description: '사이드바 > 비즈프로필 인증 관리 클릭',
    type: 'click',
  },
  click_map_current_position: {
    description: '지도 > 현재 위치 버튼 클릭',
    type: 'click',
  },
  click_map_find_path: {
    description: '지도 > 길찾기 버튼 클릭',
    type: 'click',
  },
  click_broker_hot_articles_write: {
    description: '중개사 인기매물 > 중개사 매물 올리기 클릭',
    type: 'click',
  },
  click_broker_hot_articles_guide: {
    description: '중개사 인기매물 > 당근부동산 활용 방법 보기 클릭',
    type: 'click',
  },
  show_cor_address_form: {
    description: '글쓰기 > 등기소 폼 노출',
    type: 'impression',
  },
  show_search_juso_find_cor_address: {
    description: '글쓰기 > 등기소에서 검색하기 버튼 노출',
    type: 'impression',
  },
  show_search_juso_unit_manual_input: {
    description: 'juso unit 검색 > 직접 입력하기 버튼 노출',
    type: 'impression',
  },
  click_search_juso_find_cor_address: {
    description: 'juso 검색 > 등기소에서 검색하기 버튼 클릭',
    type: 'click',
  },
  click_search_juso_unit_manual_input: {
    description: 'juso unit 검색 > 직접 입력하기 버튼 클릭',
    type: 'click',
  },
  click_search_juso_unit_select: {
    description: 'juso unit 검색 > 검색 결과 클릭',
    type: 'click',
  },
  click_main_area_mode_filter: {
    description: '메인 > 평수로 보기 클릭',
    type: 'click',
  },
};
