/**
 * @generated SignedSource<<34f5c66024db4fc3bcaa3bb7ba9bd943>>
 * @relayHash bdc5f0f8e4ba3392ea375b168f93619a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bdc5f0f8e4ba3392ea375b168f93619a

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIdentificationQuery$variables = Record<PropertyKey, never>;
export type useIdentificationQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"userIdentification_user">;
  } | null;
};
export type useIdentificationQuery = {
  response: useIdentificationQuery$data;
  variables: useIdentificationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "KarrotUserIdentification",
    "kind": "LinkedField",
    "name": "karrotUserIdentification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BirthDate",
        "kind": "LinkedField",
        "name": "birthDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "day",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "di",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "karrotUserId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "realName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "verifyTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useIdentificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "userIdentification_user",
            "selections": (v0/*: any*/),
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useIdentificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "bdc5f0f8e4ba3392ea375b168f93619a",
    "metadata": {},
    "name": "useIdentificationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b68a66a20522e612dafff65708ff9c3d";

export default node;
