const ARTICLE_CARD_SECTIONS = [
  'main_halfview',
  'main_selectedArticle',
  'detail_more',
  'my_watch',
  'my_write',
  'verify_articles',
  'my_sales_history',
  'recent_watched',
  'marketing_price_search_result',
  'broker_hot_articles',
] as const;

const ARTICLE_CAROUSEL_SECTIONS = [
  'watch_recommend',
  'similar',
  'recommend',
  'main_watch',
  'main_broker',
  'checkable_carousel',
  'recent_watched',
  'my_realty_fortune',
  'realty_recap',
] as const;

const ARTICLE_GRID_SECTIONS = ['biz_profile_articles', 'event_mbti_result'] as const;

const MY_ARTICLE_ITEM_SECTIONS = ['my_sales_history'] as const;

export const ArticleItemSections = {
  card: ARTICLE_CARD_SECTIONS,
  carousel: ARTICLE_CAROUSEL_SECTIONS,
  grid: ARTICLE_GRID_SECTIONS,
};

export type ArticleCardSection = (typeof ArticleItemSections.card)[number];

export type ArticleCarouselSection = (typeof ArticleItemSections.carousel)[number];

export type ArticleGridSection = (typeof ArticleItemSections.grid)[number];

export type MyArticleItemSection = (typeof MY_ARTICLE_ITEM_SECTIONS)[number];

export type ArticleItemSection =
  | ArticleCardSection
  | ArticleCarouselSection
  | ArticleGridSection
  | MyArticleItemSection;
