/**
 * @generated SignedSource<<731479bc835234dbefc108cc8ee49cbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticlePublishedAtText_article$data = {
  readonly publishedAt: string;
  readonly pullUpCount: number;
  readonly " $fragmentType": "ArticlePublishedAtText_article";
};
export type ArticlePublishedAtText_article$key = {
  readonly " $data"?: ArticlePublishedAtText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlePublishedAtText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticlePublishedAtText_article"
};

(node as any).hash = "f60b104815f3e6b264c219b57ec6492f";

export default node;
