import React from 'react';
import VisibilityMotion from 'components/Base/VisibilityMotion';
import { PropsOf } from 'utils/Type';
import { cn } from 'styles/utils';

type Props = PropsOf<typeof VisibilityMotion>;

const Dim: React.FC<Props> = ({ className, ...props }) => {
  return (
    <VisibilityMotion
      {...props}
      className={cn(
        'bg-palette-static-black-alpha-500 z-overlay absolute bottom-0 left-0 right-0 top-0 overflow-hidden',
        className
      )}
      initial="hide"
      animate="show"
      exit="hide"
    />
  );
};

export default Dim;
