import { cn } from 'styles/utils';
import { type CSSProperties, type HTMLAttributes, forwardRef } from 'react';
import { checkIsBigFont } from 'utils/style';

export const bottomFixedPadding = `calc(${checkIsBigFont() ? '120px' : '80px'} + env(safe-area-inset-bottom))`;

/* TODO: seed에서 cssVars 제공되면 변경 예정 */
export const appBarMargin = `var(--app-bar-margin, 0)`;

export const PageContainer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('h-full overflow-y-hidden', className)} {...props} />
);

export const ScrollContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, style, ...props }, ref) => (
    <div
      ref={ref}
      style={
        {
          ...style,
          '--bottom-fixed-padding': bottomFixedPadding,
        } as CSSProperties
      }
      className={cn(
        'relative h-full overflow-y-auto',
        'pb-[40px]',
        `has-[.bottom-fixed]:pb-[calc(var(--bottom-fixed-padding)+env(safe-area-inset-bottom))]`,
        className
      )}
      {...props}
    />
  )
);

export const PaddingContainer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mx-4 py-4', className)} {...props} />
);

export const SectionContainer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('px-4', className)} {...props} />
);
