import { HStack } from 'components/Base/Stack/HStack';
import { Stack } from 'components/Base/Stack/Stack';
import { Text } from '@seed-design/react';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  spacing?: number;
}>;

const FilterSection = ({ title, description, spacing = 12, children }: Props) => {
  return (
    <Stack spacing={spacing}>
      {title && (
        <HStack spacing={6}>
          <Text textStyle="t5Bold">{title}</Text>
          {description && (
            <Text color="palette.gray800" textStyle="t4Regular">
              {description}
            </Text>
          )}
        </HStack>
      )}
      <div>{children}</div>
    </Stack>
  );
};

export default FilterSection;
