import { lazy } from 'react';

import Path from 'constants/path';
import { IS_DEV } from 'config';
import { ActivityComponentType } from '@stackflow/react';
import { UnionToIntersection } from 'utils/Type';
import { lazyWithPreload } from 'utils/preload';

const PageMain = lazy(() => import('pages/Main'));
const PageMy = lazy(() => import('pages/My'));
const PageSearchRegion = lazy(() => import('pages/SearchRegion'));
const PageSearchUniversity = lazy(() => import('pages/SearchUniversity'));
const PageFaq = lazy(() => import('pages/Faq'));
const PageFilter = lazy(() => import('pages/Filter'));
const PageBrokerHotArticles = lazy(() => import('pages/BrokerHotArticles'));

export const PageArticle = lazyWithPreload(() => import('pages/ArticleDetail'));
const PageArticleCompare = lazy(() => import('pages/ArticleCompare'));
const PageArticleImages = lazy(() => import('pages/ArticleImages'));
const PageArticleChannelList = lazy(() => import('pages/ArticleChannelList'));
const PagePullupArticle = lazy(() => import('pages/ArticlePullup'));
const PageArticleRoadView = lazy(() => import('pages/ArticleRoadView'));

const PageAIConversion = lazy(() => import('pages/AIConversion'));

const PageArticleStepEdit = lazy(() => import('pages/ArticleStepEdit'));
const PageEditArticle = lazy(() => import('pages/ArticleEdit'));
const PageEditArticleImage = lazy(() => import('pages/ArticleEditImage'));
const PageArticleCheckList = lazy(() => import('pages/ArticleCheckList'));
const PageArticleCheckListMemo = lazy(() => import('pages/ArticleCheckListMemo'));
const PageArticleTradeTool = lazy(() => import('pages/ArticleTradeTool'));
const PageArticleTradeToolDetail = lazy(() => import('pages/ArticleTradeToolDetail'));
const PageArticleRequestVerify = lazy(() => import('pages/ArticleRequestVerify'));
const PageArticleVerify = lazy(() => import('pages/ArticleVerify'));

const PageSearchAddress = lazy(() => import('pages/SearchAddress'));

const PageVerifyArticles = lazy(() => import('pages/VerifyArticles'));
const PageArticleTradeSurvey = lazy(() => import('pages/ArticleTradeSurvey'));

const PageNewArticle = lazy(() => import(/* webpackChunkName: "article_new" */ 'pages/ArticleNew'));
const PageSearchJusoAddress = lazy(
  () => import(/* webpackChunkName: "article_new" */ 'pages/SearchJusoAddress')
);
const PageSearchJusoUnit = lazy(
  () => import(/* webpackChunkName: "article_new" */ 'pages/SearchJusoUnit')
);

const PageSubscribe = lazy(() => import(/* webpackChunkName: "subscribe" */ 'pages/Subscribe'));
const PageSubscribeSetting = lazy(
  () => import(/* webpackChunkName: "subscribe" */ 'pages/SubscribeSetting')
);
const PageSubscribeRegion = lazy(
  () => import(/* webpackChunkName: "subscribe" */ 'pages/SubscribeRegion')
);
const PageSubscribeOnboarding = lazy(
  () => import(/* webpackChunkName: "subscribe_onboarding" */ 'pages/SubscribeOnboarding')
);
const PageArticleLandTitle = lazy(
  () => import(/* webpackChunkName: "landTitle" */ 'pages/ArticleLandTitle')
);
const PageArticleLandTitleCertificate = lazy(
  () => import(/* webpackChunkName: "landTitle" */ 'pages/ArticleLandTitleCertificate')
);
const PageArticleSunlight = lazy(() => import('pages/ArticleSunlight'));

const PageSatisfactionSurvey = lazy(() => import('pages/SatisfactionSurvey'));

const PageReview = lazy(() => import(/* webpackChunkName: "review" */ 'pages/Review'));
const PageReviewNew = lazy(() => import(/* webpackChunkName: "review" */ 'pages/ReviewNew'));
const PageTradeReviewNew = lazy(
  () => import(/* webpackChunkName: "review" */ 'pages/TradeReviewNew')
);
const PageTradeReviewSelectBuyer = lazy(
  () => import(/* webpackChunkName: "review" */ 'pages/TradeReviewSelectBuyer')
);
const PageSendReviews = lazy(() => import(/* webpackChunkName: "review" */ 'pages/SendReviews'));
const PageSellerReviews = lazy(
  () => import(/* webpackChunkName: "review" */ 'pages/SellerReviews')
);

const PageGuide = lazy(() => import(/* webpackChunkName: "guide" */ 'pages/Guide'));
const PageSmsIdentification = lazy(
  () => import(/* webpackChunkName: "sms_identification" */ 'pages/SmsIdentification')
);

const PageMySalesHistory = lazy(
  () => import(/* webpackChunkName: "sales_history" */ 'pages/MySalesHistory')
);

const PageArticleDraft = lazy(() => import('pages/ArticleDraft'));
const PageImageGuide = lazy(() => import('pages/ImageGuide'));
const PageSettingNotification = lazy(() => import('pages/SettingNotification'));
const PageAdvertisementCreate = lazy(() => import('pages/AdvertisementCreate'));
const PageArticleChangeCorAddress = lazy(() => import('pages/ArticleChangeCorAddress'));
const RecentViewedArticles = lazy(() => import('pages/RecentViewedArticles'));
const PageBrokerPreOpenApplication = lazy(() => import('pages/BrokerPreOpenApplication'));
const PageBrokerOnboarding = lazy(() => import('pages/BrokerOnboarding'));

const PageBizProfileApprovalSetting = lazy(() => import('pages/BizProfileApprovalSetting'));
const PageBizProfileReviews = lazy(() => import('pages/BizProfileReviews'));
const PageBizProfileArticles = lazy(() => import('pages/BizProfileArticles'));

const PageBizProfileCreate = lazy(() => import('pages/BizProfileCreate'));
const PageBizProfileBusinessCertificate = lazy(() => import('pages/BizProfileBusinessCertificate'));
const PageBizProfileOwnerVerification = lazy(() => import('pages/BizProfileOwnerVerification'));
const PageBizProfileInfo = lazy(() => import('pages/BizProfileInfo'));

const PageMarketingPriceSearch = lazy(() => import('pages/MarketingPriceSearch'));
const PageMarketingPriceSearchResult = lazy(() => import('pages/MarketingPriceSearchResult'));

const PageMyRealtyFortune = lazy(() => import('pages/MyRealtyFortune'));
const PageMyRealtyFortuneResult = lazy(() => import('pages/MyRealtyFortuneResult'));

const PageMap = lazy(() => import('pages/Map'));

const PageComplete = lazy(() => import('pages/Complete'));

const PageBizProfileOwnerVerificationFailed = lazy(
  () => import('pages/BizProfileOwnerVerificationFailed')
);

const AlphaLogin = lazy(() => import(/* webpackChunkName: "dev" */ 'pages/AlphaLogin'));

const PageFormBuildingUsage = lazy(() => import('pages/FormBuildingUsage'));

const PageBizProfileVerificationManage = lazy(() => import('pages/BizProfileVerificationManage'));

type Route = {
  path: (typeof Path)[keyof typeof Path];
  component: ActivityComponentType<any>;
  name: string;
};

const routes = [
  {
    path: Path.Main,
    component: PageMain,
    name: 'main',
  },
  {
    path: Path.Articles,
    component: PageMain,
    name: 'main_article',
  },
  {
    path: Path.NewArticle,
    component: PageNewArticle,
    name: 'article_new',
  },
  {
    path: Path.AIConversion,
    component: PageAIConversion,
    name: 'ai_conversion',
  },
  {
    path: Path.ArticleStepEdit,
    component: PageArticleStepEdit,
    name: 'article_step_edit',
  },
  {
    path: Path.ArticleDraft,
    component: PageArticleDraft,
    name: 'article_draft',
  },
  {
    path: Path.Article,
    component: PageArticle,
    name: 'article_detail',
  },
  {
    path: Path.ArticleCompare,
    component: PageArticleCompare,
    name: 'article_compare',
  },
  {
    path: Path.ArticleRoadView,
    component: PageArticleRoadView,
    name: 'article_road_view',
  },
  {
    path: Path.ArticleImages,
    component: PageArticleImages,
    name: 'article_images',
  },
  {
    path: Path.EditArticle,
    component: PageEditArticle,
    name: 'article_edit',
  },
  {
    path: Path.EditArticleImage,
    component: PageEditArticleImage,
    name: 'article_edit_image',
  },
  {
    path: Path.PullupArticle,
    component: PagePullupArticle,
    name: 'article_pullup',
  },
  {
    path: Path.ArticleChannelList,
    component: PageArticleChannelList,
    name: 'article_channel_list',
  },
  {
    path: Path.ArticleTradeSurvey,
    component: PageArticleTradeSurvey,
    name: 'article_trade_survey',
  },
  {
    path: Path.SearchAddress,
    component: PageSearchAddress,
    name: 'search_address',
  },
  {
    path: Path.SearchJusoAddress,
    component: PageSearchJusoAddress,
    name: 'search_juso_address',
  },
  {
    path: Path.SearchJusoUnit,
    component: PageSearchJusoUnit,
    name: 'search_juso_unit',
  },
  {
    path: Path.ArticleCheckList,
    component: PageArticleCheckList,
    name: 'article_checklist',
  },
  {
    path: Path.ArticleCheckListMemo,
    component: PageArticleCheckListMemo,
    name: 'article_checklist_memo',
  },
  {
    path: Path.ArticleTradeTool,
    component: PageArticleTradeTool,
    name: 'article_trade_tool',
  },
  {
    path: Path.ArticleTradeToolDetail,
    component: PageArticleTradeToolDetail,
    name: 'article_trade_tool_detail',
  },
  {
    path: Path.ArticleChangeCorAddress,
    component: PageArticleChangeCorAddress,
    name: 'article_change_cor_address',
  },
  {
    path: Path.ArticleRequestVerify,
    component: PageArticleRequestVerify,
    name: 'article_request_verify',
  },
  {
    path: Path.ArticleVerify,
    component: PageArticleVerify,
    name: 'article_verify',
  },
  {
    path: Path.ArticleLandTitle,
    component: PageArticleLandTitle,
    name: 'land_title',
  },
  {
    path: Path.ArticleLandTitleCertificate,
    component: PageArticleLandTitleCertificate,
    name: 'land_title_certificate',
  },
  {
    path: Path.ArticleSunlight,
    component: PageArticleSunlight,
    name: 'article_sunlight',
  },
  {
    path: Path.SearchRegion,
    component: PageSearchRegion,
    name: 'search_region',
  },
  {
    path: Path.SearchUniversity,
    component: PageSearchUniversity,
    name: 'search_university',
  },
  {
    path: Path.Subscribe,
    component: PageSubscribe,
    name: 'subscribe',
  },
  {
    path: Path.SubscribeSetting,
    component: PageSubscribeSetting,
    name: 'subscribe_setting',
  },
  {
    path: Path.SubscribeRegion,
    component: PageSubscribeRegion,
    name: 'subscribe_region',
  },
  {
    path: Path.SubscribeOnboarding,
    component: PageSubscribeOnboarding,
    name: 'subscribe_onboarding',
  },
  {
    path: Path.SatisfactionSurvey,
    component: PageSatisfactionSurvey,
    name: 'satisfaction_survey',
  },
  {
    path: Path.SettingNotification,
    component: PageSettingNotification,
    name: 'setting_notification',
  },
  {
    path: Path.My,
    component: PageMy,
    name: 'my',
  },
  {
    path: Path.Review,
    component: PageReview,
    name: 'review',
  },
  {
    path: Path.ReviewNew,
    component: PageReviewNew,
    name: 'review_new',
  },
  {
    path: Path.TradeReviewNew,
    component: PageTradeReviewNew,
    name: 'trade_review_new',
  },
  {
    path: Path.TradeReviewSelectBuyer,
    component: PageTradeReviewSelectBuyer,
    name: 'trade_review_select_buyer',
  },
  {
    path: Path.SendReviews,
    component: PageSendReviews,
    name: 'send_reviews',
  },
  {
    path: Path.SellerReviews,
    component: PageSellerReviews,
    name: 'seller_reviews',
  },
  {
    path: Path.Guide,
    component: PageGuide,
    name: 'article_guide',
  },
  {
    path: Path.ImageGuide,
    component: PageImageGuide,
    name: 'image_guide',
  },
  {
    path: Path.Filter,
    component: PageFilter,
    name: 'filter',
  },
  {
    path: Path.SmsIdentification,
    component: PageSmsIdentification,
    name: 'sms_identification',
  },
  {
    path: Path.MySalesHistory,
    component: PageMySalesHistory,
    name: 'my_sales_history',
  },
  {
    path: Path.VerifyArticles,
    component: PageVerifyArticles,
    name: 'verify_articles',
  },
  {
    path: Path.Faq,
    component: PageFaq,
    name: 'faq',
  },
  {
    path: Path.AdvertisementCreate,
    component: PageAdvertisementCreate,
    name: 'advertisement_create',
  },
  {
    path: Path.RecentViewedArticles,
    component: RecentViewedArticles,
    name: 'recent_viewed_articles',
  },
  {
    path: Path.BrokerPreOpenApplication,
    component: PageBrokerPreOpenApplication,
    name: 'broker_pre_open_application',
  },
  {
    path: Path.BrokerOnboarding,
    component: PageBrokerOnboarding,
    name: 'broker_onboarding',
  },
  {
    path: Path.BizProfileApprovalSetting,
    component: PageBizProfileApprovalSetting,
    name: 'biz_profile_approval_setting',
  },
  {
    path: Path.BizProfileReviews,
    component: PageBizProfileReviews,
    name: 'biz_profile_reviews',
  },
  {
    path: Path.BizProfileArticles,
    component: PageBizProfileArticles,
    name: 'biz_profile_articles',
  },
  {
    path: Path.BizProfileOwnerVerificationFailed,
    component: PageBizProfileOwnerVerificationFailed,
    name: 'biz_profile_owner_verification_failed',
  },
  {
    path: Path.Complete,
    component: PageComplete,
    name: 'complete',
  },
  {
    path: Path.BizProfileCreate,
    component: PageBizProfileCreate,
    name: 'biz_profile_create',
  },
  {
    path: Path.BizProfileBusinessCertificate,
    component: PageBizProfileBusinessCertificate,
    name: 'biz_profile_business_certificate',
  },
  {
    path: Path.BizProfileInfo,
    component: PageBizProfileInfo,
    name: 'biz_profile_info',
  },
  {
    path: Path.BizProfileOwnerVerification,
    component: PageBizProfileOwnerVerification,
    name: 'biz_profile_owner_verification',
  },
  {
    path: Path.MarketingPriceSearch,
    component: PageMarketingPriceSearch,
    name: 'marketing_price_search',
  },
  {
    path: Path.MarketingPriceSearchResult,
    component: PageMarketingPriceSearchResult,
    name: 'marketing_price_search_result',
  },
  {
    path: Path.MyRealtyFortune,
    component: PageMyRealtyFortune,
    name: 'my_realty_fortune',
  },
  {
    path: Path.MyRealtyFortuneResult,
    component: PageMyRealtyFortuneResult,
    name: 'my_realty_fortune_result',
  },
  {
    path: Path.FormBuildingUsage,
    component: PageFormBuildingUsage,
    name: 'form_building_usage',
  },
  {
    path: Path.BizProfileVerificationManage,
    component: PageBizProfileVerificationManage,
    name: 'biz_profile_verification_manage',
  },
  { path: Path.Map, component: PageMap, name: 'map' },
  {
    path: Path.BrokerHotArticles,
    component: PageBrokerHotArticles,
    name: 'broker_hot_articles',
  },
  ...(IS_DEV
    ? [
        {
          path: Path.AlphaLogin,
          component: AlphaLogin,
          name: 'alpha_login',
        } as const,
      ]
    : []),
] as const satisfies readonly Route[];

type RouteType = (typeof routes)[number];
export type RouteName = RouteType['name'];

type ToParams<T extends { [key: string]: any }> = { [key: string]: string | undefined } & {
  [key in keyof T]: T[key];
};

type ExtractRouteOptionalParam<
  T extends string,
  U = string | number | boolean,
> = T extends `${infer Param}?`
  ? { [k in Param]?: U }
  : T extends `${infer Param}*`
    ? { [k in Param]?: U }
    : T extends `${infer Param}+`
      ? { [k in Param]: U }
      : { [k in T]: U };

type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
  ? { [k in string]?: U }
  : T extends `${string}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${string})`
      ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
      : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${string}:${infer ParamWithOptionalRegExp}`
      ? ParamWithOptionalRegExp extends `${infer Param}(${string})`
        ? ExtractRouteOptionalParam<Param, U>
        : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
      : object;

export type RouteComponentMap<T extends RouteType = RouteType> = UnionToIntersection<
  T extends {
    name: infer Name extends string;
    component: ActivityComponentType<infer Params>;
    path: infer Path extends string;
  }
    ? {
        [key in Name]: React.ComponentType<{
          params: ToParams<ExtractRouteParams<Path, string> & Params>;
        }>;
      }
    : never
>;

export type RoutePathMap<T extends RouteType = RouteType> = UnionToIntersection<
  T extends {
    name: infer Name extends string;
    path: infer Path;
  }
    ? { [key in Name]: Path }
    : never
>;

export default routes;
