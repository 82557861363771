import React, { useState } from 'react';
import { copyToClipboard } from 'utils/Clipboard';
import { toast } from 'bridge/toast';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import { IconSquare2StackedLine, IconArrowUpRightLine } from '@daangn/react-monochrome-icon';
import { openExternalUrl } from 'utils/Url';
import { HStack } from './Stack/HStack';
import { Stack } from './Stack/Stack';

type Props = {
  to: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkWithCopy = ({ to, children, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSheet = () => setIsOpen(true);
  const closeSheet = () => setIsOpen(false);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    props.onClick?.(e);
    openSheet();
  };

  const handleCopyClick = () => {
    copyToClipboard(to)
      .then(() => {
        toast('클립보드에 복사되었어요.');
      })
      .catch(() => {
        toast('링크 복사에 실패했어요.');
      });

    closeSheet();
  };

  const handleSiteClick = () => {
    openExternalUrl(to);

    closeSheet();
  };

  return (
    <>
      <a href={to} {...props} onClick={handleLinkClick}>
        {children}
      </a>
      <BottomSheet isOpen={isOpen} onClose={closeSheet} showCloseButton={false}>
        <Stack spacing={0} className="t4-regular p-[7.5]">
          <HStack spacing={12} onClick={handleCopyClick} className="py-[11px]">
            <IconSquare2StackedLine width={24} height={24} />
            <span>주소 복사</span>
          </HStack>
          <HStack spacing={12} onClick={handleSiteClick} className="py-[11px]">
            <IconArrowUpRightLine width={24} height={24} />
            <span>사이트 열기</span>
          </HStack>
        </Stack>
      </BottomSheet>
    </>
  );
};

export default LinkWithCopy;
