/**
 * @generated SignedSource<<a0d67f385bab3255b7ebb1073de86fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type BizProfileBrokerRoleEnum = "ADMIN" | "OWNER";
import { FragmentRefs } from "relay-runtime";
export type bizProfileBroker_viewer$data = {
  readonly bizProfileBroker: {
    readonly autoApproveArticles: boolean;
    readonly bizProfile: {
      readonly businessCompanyName: string;
      readonly businessOwnerName: string;
      readonly businessRegistrationNumber: string;
      readonly createdAt: string;
      readonly id: string;
      readonly jibunAddress: string;
      readonly licenseOwnerContact: string;
      readonly licenseOwnerName: string;
      readonly licenseRegistrationNumber: string;
      readonly localProfileId: string;
      readonly name: string;
      readonly originalId: string;
      readonly roadAddress: string;
      readonly thumbImageUrl: string;
      readonly verificationInfo: {
        readonly businessVerified: boolean;
        readonly identityVerified: boolean;
        readonly ownerVerified: boolean;
        readonly placeVerified: boolean;
        readonly updatedAt: string | null;
      };
    };
    readonly id: string;
    readonly role: BizProfileBrokerRoleEnum;
    readonly userPhoneNumber: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "bizProfileBroker_viewer";
};
export type bizProfileBroker_viewer$key = {
  readonly " $data"?: bizProfileBroker_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"bizProfileBroker_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bizProfileBroker_viewer"
};

(node as any).hash = "8e022240305a00ed89f51c875c4d0ee3";

export default node;
