import { cn } from 'styles/utils';
import { type HTMLAttributes } from 'react';

type DividerType = 'base' | 'nav';

type DividerProps = {
  type?: DividerType;
  rounded?: boolean;
  hideOnEdge?: boolean;
  orientation?: 'horizontal' | 'vertical';
} & HTMLAttributes<HTMLDivElement>;

const Divider = ({
  type = 'nav',
  rounded,
  hideOnEdge,
  orientation = 'horizontal',
  className,
  ...props
}: DividerProps) => (
  <div
    data-orientation={orientation}
    className={cn(
      'border-0 [&+&]:hidden',
      type === 'base'
        ? 'border-stroke-neutral-muted data-[orientation=horizontal]:border-b data-[orientation=vertical]:border-l'
        : 'border-stroke-neutral data-[orientation=horizontal]:border-b data-[orientation=vertical]:border-l',
      rounded && 'rounded-t-6',
      hideOnEdge && '[&:first-child]:hidden [&:last-child]:hidden',
      className
    )}
    {...props}
  />
);

export default Divider;
