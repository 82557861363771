import { Stack } from 'components/Base/Stack/Stack';
import { RangeSlider } from '@daangn/sprout-components-slider';
import { Text } from '@seed-design/react';
import { useEffect, useMemo, useState } from 'react';
import { Nilable } from 'utils/Type';

type Props = {
  minValue: Nilable<number>;
  maxValue: Nilable<number>;
  onChange: (minValue: number, maxValue: number) => void;
  range: number[];
  title?: string;
  formatValue?: (value: number) => string;
};

const findValueIndex = (range: number[], value: Nilable<number>, fallback: number) => {
  const index = range.findIndex((rangeValue) => rangeValue === value);
  return index === -1 ? fallback : index;
};

export const getIndexValue = (
  range: number[],
  minValue: Nilable<number>,
  maxValue: Nilable<number>
) => {
  const minIndex = findValueIndex(range, minValue, 0);
  const maxIndex = findValueIndex(range, maxValue, range.length - 1);

  return [minIndex, maxIndex];
};

export const getRangeText = (
  min: Nilable<number>,
  max: Nilable<number>,
  formatValue = (v: number) => String(v)
) => {
  const leftText = typeof min !== 'number' ? null : formatValue(min);
  const rightText = typeof max !== 'number' ? null : formatValue(max);

  if (leftText === null && rightText === null) {
    return '전체';
  }
  if (leftText === null && rightText !== null) {
    return `${rightText} 이하`;
  }
  if (leftText !== null && rightText === null) {
    return `${leftText} 이상`;
  }

  return `${leftText}~${rightText}`;
};

const RangeFilter = ({
  minValue,
  maxValue,
  onChange,
  range,
  title,
  formatValue = (v) => String(v),
}: Props) => {
  const [indexValue, setIndexValue] = useState(() => getIndexValue(range, minValue, maxValue));
  const [minIndex, maxIndex] = indexValue;

  const rangeText = useMemo(
    () =>
      getRangeText(
        minIndex === 0 ? null : range[minIndex],
        maxIndex === range.length - 1 ? null : range[maxIndex],
        formatValue
      ),
    [minIndex, maxIndex, range, formatValue]
  );

  const handleChange = ([minIndex, maxIndex]: number[]) => {
    setIndexValue([minIndex, maxIndex]);
  };

  const handleAfterChange = ([minIndex, maxIndex]: number[]) => {
    setIndexValue([minIndex, maxIndex]);
    onChange(range[minIndex], range[maxIndex]);
  };

  useEffect(() => {
    setIndexValue([
      findValueIndex(range, minValue, 0),
      findValueIndex(range, maxValue, range.length - 1),
    ]);
  }, [minValue, maxValue]);

  return (
    <Stack spacing={16}>
      <Stack spacing={6}>
        {title && <Text textStyle="t4Bold">{title}</Text>}
        <Text textStyle="t4Regular">{rangeText}</Text>
      </Stack>
      <RangeSlider
        UNSAFE_className="[&_[data-part='marker-group']]:mt-3 [&_[data-part='marker-group']]:text-palette-gray-800"
        minValue={0}
        maxValue={range.length - 1}
        value={[minIndex, maxIndex]}
        onChange={handleChange}
        onChangeEnd={handleAfterChange}
        markers={[
          {
            value: 0,
            label: formatValue(range[0]),
            align: 'start',
          },
          {
            value: range.length / 2,
            label: '',
          },
          {
            value: range.length - 1,
            label: [formatValue(range[range.length - 2]), '이상'].filter(Boolean).join(' '),
            align: 'end',
          },
        ]}
      />
    </Stack>
  );
};

export default RangeFilter;
