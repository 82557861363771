import { Spinner } from '@daangn/sprout-components-spinner';
import PageLevelModal from './Portal/PagePortal';

type Props = {
  dimmed?: boolean;
};

const GlobalLoading = ({ dimmed }: Props) => {
  return (
    <PageLevelModal isOpen onClose={() => {}}>
      {dimmed && <PageLevelModal.Dim />}
      <PageLevelModal.Body>
        <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner />
        </div>
      </PageLevelModal.Body>
    </PageLevelModal>
  );
};

export default GlobalLoading;
