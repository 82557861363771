import { cn } from 'styles/utils';

const baseThumbnail = cn([
  'relative flex-shrink-0',
  'z-docked rounded-2 border border-stroke-on-image',
  'overflow-hidden',
  '[&_img]:h-full [&_img]:w-full',
]);

export type ThumbnailProps = {
  width?: string | number;
  height?: string | number;
};

const Thumbnail = ({
  width = 118,
  height = 118,
  className,
  ...props
}: ThumbnailProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(baseThumbnail, className)}
      style={{ width: `${width}px`, height: `${height}px` }}
      {...props}
    />
  );
};

export type ThumbnailRatioProps = {
  ratio: '2/1' | '16/9' | '4/3' | '1/1';
};

export const ThumbnailRatio = ({
  ratio,
  className,
  ...props
}: ThumbnailRatioProps & React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn(baseThumbnail, className)} style={{ aspectRatio: ratio }} {...props} />;
};

export default Thumbnail;
