/**
 * @generated SignedSource<<d8cd2f05eff0cb2a60bcbe94538a3371>>
 * @relayHash 9d1d2d685a4025613f5f940a99d31655
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9d1d2d685a4025613f5f940a99d31655

import { ConcreteRequest } from 'relay-runtime';
export type MakeViewerWatchArticleInput = {
  originalArticleId: string;
};
export type useWatchArticle_MakeViewerWatchArticleMutation$variables = {
  connections: ReadonlyArray<string>;
  input: MakeViewerWatchArticleInput;
};
export type useWatchArticle_MakeViewerWatchArticleMutation$data = {
  readonly makeViewerWatchArticle: {
    readonly result?: {
      readonly article: {
        readonly id: string;
        readonly isViewerWatched: boolean;
        readonly watchCount: number;
      };
    };
  };
};
export type useWatchArticle_MakeViewerWatchArticleMutation = {
  response: useWatchArticle_MakeViewerWatchArticleMutation$data;
  variables: useWatchArticle_MakeViewerWatchArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "article",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerWatched",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useWatchArticle_MakeViewerWatchArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "makeViewerWatchArticle",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MakeViewerWatchArticleOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MakeViewerWatchArticleOutput_Result",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useWatchArticle_MakeViewerWatchArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "makeViewerWatchArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MakeViewerWatchArticleOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "prependNode",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "article",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      },
                      {
                        "kind": "Literal",
                        "name": "edgeTypeName",
                        "value": "ViewerWatchArticleEdge"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MakeViewerWatchArticleOutput_Result",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9d1d2d685a4025613f5f940a99d31655",
    "metadata": {},
    "name": "useWatchArticle_MakeViewerWatchArticleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e711fea0fe535b0aad43359720ca515e";

export default node;
