/**
 * @generated SignedSource<<15205a1bc0977d6c677ff421a1d068e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ArticleInvisibleStatusEnum = "BLOCKED" | "CHECK_DENY" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type ArticleInvisibleState_article$data = {
  readonly invisibleStatus: ArticleInvisibleStatusEnum;
  readonly isHide: boolean;
  readonly " $fragmentType": "ArticleInvisibleState_article";
};
export type ArticleInvisibleState_article$key = {
  readonly " $data"?: ArticleInvisibleState_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleInvisibleState_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleInvisibleState_article"
};

(node as any).hash = "22c1f3337ac6b41565593cc0f0d96437";

export default node;
