import { MouseEvent } from 'react';
import { BoxButton as SproutBoxButton, SeedBoxButtonProps } from '@daangn/sprout-components-button';
import { vars } from '@seed-design/css/vars';
import FontSizeLimiter from 'components/Base/FontSizeLimiter';

export type BoxButtonProps = {
  onDisabledClick?: () => void;
} & SeedBoxButtonProps;

const BoxButton = ({
  children,
  isDisabled,
  isLoading,
  onClick,
  onDisabledClick,
  ...props
}: BoxButtonProps) => {
  const disabledProps: Omit<SeedBoxButtonProps, 'children'> = Boolean(onDisabledClick)
    ? // clickable disabled button
      {
        onClick: onDisabledClick,
        // button disabled style
        UNSAFE_style: {
          background: vars.$color.palette.gray400,
          color: vars.$color.palette.gray600,
        },
      }
    : {
        isDisabled: isDisabled,
      };

  const handleClick = (e: MouseEvent<Element>) => {
    isLoading || onClick?.(e);
  };

  return (
    <SproutBoxButton
      {...props}
      isLoading={isLoading}
      onClick={handleClick}
      {...(isDisabled && disabledProps)}
    >
      <FontSizeLimiter limit={20}>{children}</FontSizeLimiter>
    </SproutBoxButton>
  );
};

export default BoxButton;
