import { captureMessage } from '@sentry/react';
import { Activity } from '@stackflow/core';
import { makeTemplate } from '@stackflow/plugin-history-sync';
import Path from 'constants/path';
import routes, { RouteName } from 'routes';
import { actions } from 'stackflow';
import { ExtractRouteParamsType } from 'utils/Type';

export const findActivity = (path: string) => {
  for (const route of routes) {
    const template = makeTemplate(route.path);
    const match = template.parse(path);

    if (!match) {
      continue;
    }

    return {
      ...route,
      params: match,
    };
  }
  return null;
};

const _generatePath = (path: string, params: Record<string, any>) => {
  const template = makeTemplate(path);
  return template.fill(params);
};

/**
 * @example
 * generatePath('article', { articleId: 888 }) => '/article/888'
 * generatePath('article', { articleId: 888, referrer: 'feed' }) => '/article/888?referrer=feed'
 */
export function generatePath<Name extends RouteName>(
  name: Name,
  params: ExtractRouteParamsType<Name>
) {
  const route = routes.find((r) => r.name === name);
  if (!route) {
    captureMessage('[generatePath] path 생성 실패: ' + JSON.stringify({ name, params }));
    return _generatePath(Path.Main, {});
  }

  return _generatePath(route.path, params ?? {});
}

export const getLastActivity = () => {
  return actions
    .getStack()
    .activities?.filter((v) => v.transitionState !== 'exit-done')
    .slice(-2)?.[0] as (Activity & { name: RouteName }) | undefined;
};

export const getCurrentPageRouteName = () => {
  return findActivity(window.location.pathname || '')?.name;
};

// prev 는 replace 도 포함
class PrevActivity {
  private prevActivity: Activity | null = null;

  get() {
    return this.prevActivity;
  }

  set(activity: Activity) {
    this.prevActivity = activity;
  }
}

export const prevActivity = new PrevActivity();
