import type { AreaUnit } from '@daangn/realty-sdk/services';
import type { ArticleItemWrapper_article$key } from '__generated__/ArticleItemWrapper_article.graphql';
import type { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import ImpressionLog from 'components/Base/ImpressionLog';
import InAppLink from 'components/Base/InAppLink';
import type { ArticleItemSection } from 'constants/section';
import { useAdvertisementItem } from 'hooks/useAdvertisementItem';
import useArticleItemEventParams from 'hooks/useArticleItemEventParams';
import { CompoundLoggerComponentProps } from 'logger/types/common';
import { ArticleItemDefaultEventParams } from 'logger/types/params';
import type { PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import { readArticleMainTrade, type TradeFilterOptions } from 'utils/Article';
import Logger from 'utils/Logger';

export type ArticleItemWrapperLoggerProps = CompoundLoggerComponentProps<
  'show_article_item' | 'click_article_item',
  ArticleItemDefaultEventParams
>;

type Props = PropsWithChildren<
  {
    articleRef: ArticleItemWrapper_article$key;
    section: ArticleItemSection;
    index: number;
    areaUnit?: AreaUnit;
    searchKeyword?: string;
    advertisementInfoRef?: useAdvertisementInfo_advertisementInfo$key | null;
    tradeFilterOptions?: TradeFilterOptions;
    onNavigate?: (activityId: string) => void;
    onImpression?: () => void;
    onClick?: () => void;
  } & ArticleItemWrapperLoggerProps
>;

const ArticleItemWrapper = ({
  articleRef,
  advertisementInfoRef,
  section,
  index,
  areaUnit,
  searchKeyword,
  tradeFilterOptions,
  event,
  onNavigate,
  onImpression,
  onClick,
  children,
}: Props) => {
  const article = useFragment(
    graphql`
      fragment ArticleItemWrapper_article on Article {
        id
        originalId
        ...useArticleItemEventParams_article
        ...ArticleMainTrade_article
      }
    `,
    articleRef
  );

  const defaultEventParams = useArticleItemEventParams(article, {
    advertisementInfoRef,
    index,
    section,
  });
  const advertisementItem = useAdvertisementItem(advertisementInfoRef ?? null, section);
  const { mainTrade } = readArticleMainTrade(article, tradeFilterOptions);

  const eventParams = {
    ...defaultEventParams,
    ...event?.params,
    search_keyword: searchKeyword,
    area_unit: areaUnit,
  };

  const handleNavigate = (activityId: string) => {
    onNavigate?.(activityId);
  };

  const handleImpression = () => {
    onImpression?.();
    advertisementItem?.logger.impression();
  };

  const handleClick = () => {
    onClick?.();

    Logger.track('click_article_item', eventParams);
    advertisementItem?.logger.click();
  };

  return (
    <ImpressionLog
      event={{
        name: 'show_article_item',
        params: eventParams,
      }}
      onImpression={handleImpression}
    >
      <InAppLink
        to="article_detail"
        params={{
          articleId: article.originalId,
          trade_types: mainTrade.type,
          entry_section: section,
          entry_index: String(index),
          placement_id: advertisementItem?.placementId,
        }}
        onNavigate={handleNavigate}
        onClick={handleClick}
      >
        {children}
      </InAppLink>
    </ImpressionLog>
  );
};

export default ArticleItemWrapper;
