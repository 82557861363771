import { useActivityParams } from '@stackflow/react';
import { camelCase } from 'lodash-es';
import { useMemo } from 'react';
import type { SnakeToCamelCase } from 'utils/Type';

const useCamelCaseParams = <T extends { [key: string]: any }>(): {
  [k in keyof T as SnakeToCamelCase<k & string>]: T[k];
} => {
  const params = useActivityParams<T>();
  const entries = useMemo(
    () => Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    [params]
  );

  return Object.fromEntries(entries);
};

export default useCamelCaseParams;
