/**
 * @generated SignedSource<<fd9396eafb4c745dea5d045f3d30b9f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WriterTypeEnum = "BROKER" | "LESSOR" | "TENANT";
import { FragmentRefs } from "relay-runtime";
export type useArticleItemEventParams_article$data = {
  readonly id: string;
  readonly isWriterVerify: boolean;
  readonly originalId: string;
  readonly writerType: WriterTypeEnum | null;
  readonly " $fragmentType": "useArticleItemEventParams_article";
};
export type useArticleItemEventParams_article$key = {
  readonly " $data"?: useArticleItemEventParams_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"useArticleItemEventParams_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useArticleItemEventParams_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWriterVerify",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writerType",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "1b46ba06cea86f9f147009154a89f418";

export default node;
