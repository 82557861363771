import { receive, send } from '@stackflow/compat-await-push';
import { useActions, useActivity } from '@stackflow/react';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { ActivitiesType } from 'stackflow';

type OverloadParams<T> = T extends {
  (...args: infer Params): void;
  (...args: infer Params2): void;
  (...args: infer Params3): void;
}
  ? Params | Params2 | Params3
  : never;

const useCustomFlow = () => {
  const activity = useActivity();
  const originalFlow = useActions<ActivitiesType>();
  const [resolverMap] = useState(() => new Map<string, (data: any | undefined) => void>());

  const asyncPush = useCallback(
    <T = undefined>(...args: Parameters<typeof originalFlow.push>) =>
      receive<T>(originalFlow.push(...args)),
    [originalFlow.push]
  );

  // Async Push 후 명시적 pop을 안하더라도 현재 activity 로 다시 돌아오게 되면 resolve
  const asyncPushUntilPageActive = useCallback(
    <T = undefined>(...args: Parameters<typeof originalFlow.push>) => {
      const id = nanoid();
      const pushResult = originalFlow.push(...args);
      const asyncPushPromise = receive<T>(pushResult);

      const resolveAndRemove = (data: T | undefined) => {
        resolverMap.delete(id);
        send({
          activityId: pushResult.activityId,
          data,
        });
      };

      resolverMap.set(id, resolveAndRemove);

      return asyncPushPromise.then(resolveAndRemove);
    },
    [originalFlow.push]
  );

  const pop = useCallback(
    (...args: OverloadParams<typeof originalFlow.pop>) => {
      // @ts-expect-error pop 인자 검증 후 그대로 bypass 하기 때문에 에러 ignore
      originalFlow.pop(...args);
      return {
        send: <T = any>(activityId: string, data: T) => {
          send({
            activityId,
            data,
          });
        },
      };
    },
    [originalFlow.pop]
  );

  useEffect(() => {
    if (activity?.isActive) {
      resolverMap.forEach((resolver) => {
        resolver(undefined);
      });
    }
  }, [activity?.isActive]);

  return { ...originalFlow, pop, asyncPush, asyncPushUntilPageActive };
};

export default useCustomFlow;
