import { useDrag } from '@use-gesture/react';
import { animate } from 'framer-motion';
import React, { RefObject, useRef } from 'react';
import Logger from 'utils/Logger';
import { BottomSheetLoggerProps } from './BottomSheet';

export const HANDLER_HEIGHT = 28;
type HandlerProps = {
  wrapperRef: RefObject<HTMLDivElement>;
  minHeight: number;
} & BottomSheetLoggerProps;

export default function Handler({ minHeight, wrapperRef, event }: HandlerProps) {
  const dragY = useRef({
    start: 0,
    last: 0,
  });

  const bind = useDrag((args) => {
    const $wrapper = wrapperRef.current;

    if (!$wrapper) return;
    const { xy } = args;
    const [, y] = xy;

    switch (args.type) {
      case 'pointerdown': {
        dragY.current.start = y;
        dragY.current.last = y;
        return;
      }
      case 'pointermove': {
        const windowHeight = window.innerHeight;
        const wrapperHeight = $wrapper.clientHeight;
        const minY = windowHeight - wrapperHeight;
        const maxY = windowHeight - minHeight;
        const nextY = Math.min(maxY, Math.max(y, minY));
        dragY.current.last = y;

        $wrapper.style.transform = `translateY(${nextY - minY}px)`;
        return;
      }
      case 'pointerup': {
        const isOpen = dragY.current.start - dragY.current.last > 30;

        const yValue = isOpen ? 0 : `calc(100% - ${minHeight}px)`;

        animate(
          $wrapper,
          {
            y: yValue,
          },
          { type: 'keyframes' }
        );

        event && Logger.track('drag_bottom_sheet_handler', event.params);

        return;
      }
    }
  });

  return (
    <div className="flex-center touch-none py-3" {...bind()}>
      <div className="bg-palette-gray-400 h-1 w-9 rounded-[2]" />
    </div>
  );
}
