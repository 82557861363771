import { ArticleCarouselItemStyle } from 'components/Article/ArticleCarouselItem';
import { CarouselSectionStyle } from 'components/Base/CarouselSection';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { Stack } from '../Stack/Stack';

const ArticleCarouselSkeleton = () => {
  return (
    <div>
      <CarouselSectionStyle.Container>
        <CarouselSectionStyle.SectionContainer>
          <CarouselSectionStyle.Title>
            <Placeholder.Text>보고 계신 매물과 비슷한 매물</Placeholder.Text>
          </CarouselSectionStyle.Title>
        </CarouselSectionStyle.SectionContainer>
        <CarouselSectionStyle.List>
          {[...Array(5)].map((_, index) => (
            <ArticleCarouselItemStyle.Container key={index}>
              <ArticleCarouselItemStyle.Thumbnail>
                <Placeholder.Box className="h-full w-full" />
              </ArticleCarouselItemStyle.Thumbnail>
              <Stack spacing={4}>
                <ArticleCarouselItemStyle.SalesTypeText>
                  <Placeholder.Text>투룸이상</Placeholder.Text>
                </ArticleCarouselItemStyle.SalesTypeText>
                <ArticleCarouselItemStyle.AreaInfoText>
                  <Placeholder.Text>전세 2억 5,299</Placeholder.Text>
                </ArticleCarouselItemStyle.AreaInfoText>
                <ArticleCarouselItemStyle.TradeTypeAndPriceText>
                  <Placeholder.Text>1층</Placeholder.Text>
                </ArticleCarouselItemStyle.TradeTypeAndPriceText>
                <ArticleCarouselItemStyle.RegionText>
                  <Placeholder.Text>방배동</Placeholder.Text>
                </ArticleCarouselItemStyle.RegionText>
              </Stack>
            </ArticleCarouselItemStyle.Container>
          ))}
        </CarouselSectionStyle.List>
      </CarouselSectionStyle.Container>
    </div>
  );
};

export default ArticleCarouselSkeleton;
