/**
 * @generated SignedSource<<95b7791e5b735dfadc8aee43356ea6cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
import { FragmentRefs } from "relay-runtime";
export type ArticleBrokerCommission_article$data = {
  readonly brokerCommissions: ReadonlyArray<{
    readonly commission: string;
    readonly total: string;
    readonly tradeType: TradeTypeEnum;
    readonly vat: string;
  }>;
  readonly " $fragmentType": "ArticleBrokerCommission_article";
};
export type ArticleBrokerCommission_article$key = {
  readonly " $data"?: ArticleBrokerCommission_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleBrokerCommission_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleBrokerCommission_article"
};

(node as any).hash = "9b9319cab760377568193dfafed62b50";

export default node;
