import { ComponentPropsWithRef, CSSProperties, forwardRef } from 'react';
import { cn } from 'styles/utils';

type WrapProps = {
  /**
   * The gap between each stack item (If divider exist, spacing will applied to margin)
   * @type CSSProperties["margin"]
   * @example "1rem" | "1em" | 1
   * @default "0.5rem"
   */
  spacing?: CSSProperties['margin'];
  /**
   * The horizontal space between the each child (even if it wraps). Defaults to `spacing` if not defined.
   * @type CSSProperties["margin"]
   * @example "1rem" | "1em" | 1
   * @default "0.5rem"
   */
  spacingX?: CSSProperties['margin'];
  /**
   * The vertical space between the each child (even if it wraps). Defaults to `spacing` if not defined.
   * @type CSSProperties["margin"]
   * @example "1rem" | "1em" | 1
   * @default "0.5rem"
   */
  spacingY?: CSSProperties['margin'];
} & ComponentPropsWithRef<'div'>;

// [참고] https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/wrap/wrap.tsx
export const Wrap = forwardRef<HTMLDivElement, WrapProps>(function Wrap(props, ref) {
  const { children, className, spacing = '0.5rem', spacingX = spacing, spacingY = spacing } = props;

  return (
    <div ref={ref}>
      <ul
        className={cn('flex list-none flex-wrap', className)}
        style={{ rowGap: spacingY, columnGap: spacingX }}
      >
        {children}
      </ul>
    </div>
  );
});

Wrap.displayName = 'Wrap';

export const WrapItem = forwardRef<HTMLLIElement, ComponentPropsWithRef<'li'>>(
  function WrapItem(props, ref) {
    const { children, className, ...rest } = props;
    return (
      <li ref={ref} className={cn('flex items-start', className)} {...rest}>
        {children}
      </li>
    );
  }
);

WrapItem.displayName = 'WrapItem';
