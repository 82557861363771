import { IconXmarkCircleFill } from '@daangn/react-monochrome-icon';

type Props = {
  onClickDelete: () => void;
};

const TextFieldRemoveButton = ({ onClickDelete }: Props) => {
  return (
    <button
      className="flex h-full items-center justify-center p-0"
      tabIndex={-1}
      type="button"
      onClick={onClickDelete}
    >
      <IconXmarkCircleFill width={22} height={22} className="text-palette-gray-700" />
    </button>
  );
};

export default TextFieldRemoveButton;
