import { FC, memo, Suspense, useEffect } from 'react';
import { Stack } from 'stackflow';
import useInitApp from 'hooks/useInitApp';
import useNudgeController from 'hooks/useNudgeController';
import useDebugModeObserver from 'hooks/useDebugModeObserver';
import { AppPreloadedContextProvider, useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { LocalStorage } from 'utils/Storage';
import { UserSegmentType } from 'utils/segment';

const App: FC = () => {
  useInitApp();
  useNudgeController();
  useDebugModeObserver();

  return (
    <AppPreloadedContextProvider>
      <AppNavigator />
      <Suspense fallback={null}>
        <SegmentSetter />
      </Suspense>
    </AppPreloadedContextProvider>
  );
};

export default App;

const AppNavigator = memo(() => {
  return <Stack />;
});

const SegmentSetter = () => {
  const { bizProfileBroker } = useAppPreloadedQuery();

  useEffect(() => {
    if (bizProfileBroker) {
      LocalStorage.addUserSegment(UserSegmentType.Broker);
    }
  }, [bizProfileBroker]);

  return null;
};
