import { vars } from '@seed-design/css/vars';
import { ArticleCarouselItem_article$key } from '__generated__/ArticleCarouselItem_article.graphql';
import ArticleThumbnail from 'components/Article/ArticleThumbnail';
import { graphql, useFragment } from 'react-relay';
import {
  readArticleAreaInfoText,
  readArticleSalesTypeText,
  readArticleTradeTypeAndPriceText,
  type TradeFilterOptions,
} from 'utils/Article';
import WatchButton from './WatchButton';
import { isViewedArticleState } from 'store/viewedArticle';
import { ArticleCarouselSection } from 'constants/section';
import ArticleAdvertisementHelpButton from 'components/Article/ArticleAdvertisementHelpButton';
import { useState } from 'react';
import { actionToast } from 'utils/actionToast';
import { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import { useAdvertisementItem } from 'hooks/useAdvertisementItem';
import { useAtomValue } from 'jotai';
import ArticleStatusBadge from 'components/Article/ArticleStatusBadge';
import ArticleItemWrapper, {
  ArticleItemWrapperLoggerProps,
} from 'components/Article/ArticleItemWrapper';
import { Stack, type StackProps } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';
import type { AreaUnit } from '@daangn/realty-sdk/services';

export type ArticleCarouselItemProps = {
  articleRef: ArticleCarouselItem_article$key;
  advertisementInfoRef?: useAdvertisementInfo_advertisementInfo$key | null;
  section: ArticleCarouselSection;
  index: number;
  tradeFilterOptions?: TradeFilterOptions;
  hasWatchButton?: boolean;
  imageLazyLoad?: boolean;
  onClick?: () => void;
  showIsWatched?: boolean;
  showAdvertisementHelpButton?: boolean;
  areaUnit?: AreaUnit;
} & ArticleItemWrapperLoggerProps;

const ArticleCarouselItem = ({
  articleRef,
  advertisementInfoRef,
  tradeFilterOptions,
  section,
  index,
  hasWatchButton,
  imageLazyLoad,
  onClick,
  event,
  showIsWatched = true,
  showAdvertisementHelpButton = true,
  areaUnit,
}: ArticleCarouselItemProps) => {
  const article = useFragment(
    graphql`
      fragment ArticleCarouselItem_article on Article {
        id
        originalId
        ...ArticleItemWrapper_article
        ...ArticleSalesTypeText_article
        ...ArticleTradeTypeAndPriceText_article
        ...ArticleThumbnail_article
        ...ArticleAreaInfoText_article
        ...ArticleStatusBadge_article
        ...WatchButton_article
        status
        area
        writerType
        region {
          name
          name1
          name2
          name3
        }
      }
    `,
    articleRef
  );
  const advertisementItem = useAdvertisementItem(advertisementInfoRef ?? null, section);
  const [hideAdvertise, setHideAdvertise] = useState(false);
  const salesTypeText = readArticleSalesTypeText(article);
  const areaInfoText = readArticleAreaInfoText(article, { areaUnit });
  const tradeTypeAndPriceText = readArticleTradeTypeAndPriceText(article, tradeFilterOptions);
  const viewedDate = useAtomValue(isViewedArticleState(article.originalId));
  const isViewed = !!viewedDate;

  const handelHideAdvertise = () => {
    advertisementItem?.logger.hide();
    actionToast({
      message: '광고를 숨겼어요.',
    });
    setHideAdvertise(true);
  };

  if (hideAdvertise) {
    return null;
  }

  return (
    <ArticleItemWrapper
      articleRef={article}
      advertisementInfoRef={advertisementInfoRef}
      tradeFilterOptions={tradeFilterOptions}
      section={section}
      index={index}
      onClick={onClick}
      event={event}
      areaUnit={areaUnit}
    >
      <ArticleCarouselItemStyle.Container>
        <ArticleCarouselItemStyle.Thumbnail>
          <ArticleThumbnail
            articleRef={article}
            width={128}
            height={96}
            isWatched={showIsWatched && isViewed}
            lazy={imageLazyLoad}
          />
          {hasWatchButton && (
            <div className="z-docked absolute right-2 top-2">
              <WatchButton
                articleRef={article}
                enableClickAnimation={false}
                onClick={(e) => e.stopPropagation()}
                size={20}
                color={vars.$color.palette.staticWhite}
                position={`${section}_article_carousel`}
              />
            </div>
          )}
        </ArticleCarouselItemStyle.Thumbnail>
        <Stack spacing={4}>
          {salesTypeText && (
            <HStack spacing={4}>
              <ArticleStatusBadge articleRef={article} />
              <ArticleCarouselItemStyle.SalesTypeText>
                {salesTypeText}
              </ArticleCarouselItemStyle.SalesTypeText>
            </HStack>
          )}
          {tradeTypeAndPriceText && (
            <ArticleCarouselItemStyle.TradeTypeAndPriceText>
              {tradeTypeAndPriceText}
            </ArticleCarouselItemStyle.TradeTypeAndPriceText>
          )}
          {areaInfoText && (
            <ArticleCarouselItemStyle.AreaInfoText>
              {areaInfoText}
            </ArticleCarouselItemStyle.AreaInfoText>
          )}
          <ArticleCarouselItemStyle.RegionText>
            <HStack spacing={3.5}>
              <span className="text-ellipsis-1 whitespace-pre-wrap">{article.region.name3}</span>
              {showAdvertisementHelpButton && !!advertisementItem && (
                <>
                  <span>⸱</span>
                  <ArticleAdvertisementHelpButton
                    articleId={article.originalId}
                    onHideAdvertise={handelHideAdvertise}
                  />
                </>
              )}
            </HStack>
          </ArticleCarouselItemStyle.RegionText>
        </Stack>
      </ArticleCarouselItemStyle.Container>
    </ArticleItemWrapper>
  );
};

export default ArticleCarouselItem;

export const ArticleCarouselItemStyle = {
  Container: (props: StackProps) => <Stack spacing={8} {...props} />,
  Thumbnail: (props: React.HTMLAttributes<HTMLDivElement>) => (
    <div className="relative h-[96px] w-[128px] overflow-hidden rounded-[6px]" {...props} />
  ),
  SalesTypeText: (props: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className="t3-regular text-ellipsis-1 whitespace-pre-wrap" {...props} />
  ),
  TradeTypeAndPriceText: (props: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className="t4-bold" {...props} />
  ),
  AreaInfoText: (props: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className="t4-regular" {...props} />
  ),
  RegionText: (props: React.HTMLAttributes<HTMLSpanElement>) => (
    <span className="t3-regular text-palette-gray-700" {...props} />
  ),
};
