import { vars } from '@seed-design/css/vars';
import { omit } from 'lodash-es';
import React, {
  Children,
  cloneElement,
  ComponentPropsWithRef,
  ElementType,
  ForwardedRef,
  Fragment,
  isValidElement,
  useMemo,
} from 'react';
import { cn } from 'styles/utils';
import { tv } from 'tailwind-variants';
import { ComponentWithAs } from 'utils/Type';

type StackDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';

interface StackOptions {
  /**
   * The direction to stack the items.
   * @default "column"
   */
  direction?: StackDirection;
  /**
   * The gap between each stack item (If divider exist, spacing will applied to margin)
   * @type SeedDimensionKey | number
   * @example "x1_5" | "componentDefault" | 1
   * @default "x1_5"
   */
  spacing?: SeedDimensionKey | number;
  /**
   * If `true`, each stack item will show a divider
   * @type React.ReactElement
   */
  divider?: React.ReactElement;
}

export function forwardRef<T, P = object>(
  render: (props: P, ref: React.Ref<T>) => JSX.Element | null
): (props: P & React.RefAttributes<T>) => JSX.Element | null {
  return React.forwardRef(render as any) as any;
}

export type StackProps<C extends ElementType = 'div'> = StackOptions &
  Omit<ComponentPropsWithRef<C>, keyof StackOptions> & {
    as?: React.ElementType;
  };

// [참고] https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/stack/stack.tsx
export const Stack = forwardRef(
  <C extends ElementType = 'div'>(props: StackProps<C>, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      direction = 'column',
      spacing = 'x1_5',
      divider,
      children,
      className,
      style,
      as,
      ...rest
    } = props;

    const Component: ElementType = as || 'div';
    const styles = stackStyles({ direction });

    const clones = useMemo(() => {
      const validChildren = getValidChildren(children);

      if (!divider) return validChildren;

      return validChildren.map((child, index, arr) => {
        const key = typeof child.key !== 'undefined' ? child.key : index;
        const sep = cloneElement(divider, {
          className: cn(styles.divider(), divider.props.className),
        });

        return (
          <Fragment key={key}>
            {child}
            {index === arr.length - 1 ? null : sep}
          </Fragment>
        );
      });
    }, [children, divider]);

    return (
      <Component
        ref={ref}
        style={
          {
            ...style,
            '--stack-spacing': handleSpacing(spacing),
          } as React.CSSProperties
        }
        className={cn(styles.root(), !divider && 'gap-[--stack-spacing]', className)}
        {...rest}
      >
        {clones}
      </Component>
    );
  }
) as ComponentWithAs<'div', StackProps>;

const getValidChildren = (children: React.ReactNode) => {
  return Children.toArray(children).filter(isValidElement);
};

const stackStyles = tv({
  slots: {
    root: 'flex',
    divider: 'flex self-stretch border-solid',
  },
  variants: {
    direction: {
      column: {
        root: 'flex-col',
        divider: 'mx-0 my-[--stack-spacing]',
      },
      'column-reverse': {
        root: 'flex-col-reverse',
        divider: 'mx-0 my-[--stack-spacing]',
      },
      row: {
        root: 'flex-row',
        divider: 'mx-[--stack-spacing] my-0',
      },
      'row-reverse': {
        root: 'flex-row-reverse',
        divider: 'mx-[--stack-spacing] my-0',
      },
    },
  },
  defaultVariants: {
    direction: 'column',
  },
});

const seedDimensions = {
  ...omit(vars.$dimension, ['spacingX', 'spacingY']),
  ...vars.$dimension.spacingX,
  ...vars.$dimension.spacingY,
};

type SeedDimensionKey = keyof typeof seedDimensions;

const handleSpacing = (spacing: SeedDimensionKey | number): string => {
  if (typeof spacing === 'number') {
    return `${spacing}px`;
  }

  if (seedDimensions[spacing]) {
    return seedDimensions[spacing];
  }

  return seedDimensions.x1_5;
};
