import { vars } from '@seed-design/css/vars';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { SectionContainer } from 'components/Base/Container';
import { LoggerComponentProps } from 'logger/types/common';
import { useRef, ReactNode, ReactElement, forwardRef, type HTMLAttributes } from 'react';
import Logger from 'utils/Logger';
import { HStack, type HStackProps } from './Stack/HStack';
import { Stack, type StackProps } from './Stack/Stack';
import { cn } from 'styles/utils';
import { Spacer } from '@daangn/realty-react/ui';

type Props = {
  title: string;
  rightButton?: {
    label?: string;
    icon: ReactElement;
    onClick: () => void;
  };
  scrollRef?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
} & LoggerComponentProps<'click_more_carousel' | 'scroll_carousel'>;

export const CarouselSectionMoreIcon = () => {
  return <IconChevronRightLine color={vars.$color.palette.gray700} width={16} height={16} />;
};

const CarouselSection = ({ title, rightButton, scrollRef, children, event }: Props) => {
  const scrollFlag = useRef(false);

  const handleClickMore = () => {
    event && Logger.track('click_more_carousel', event.params);

    rightButton?.onClick();
  };

  const handleScroll = () => {
    if (scrollFlag.current) {
      return;
    }
    scrollFlag.current = true;
    event && Logger.track('scroll_carousel', event.params);
  };

  return (
    <CarouselSectionStyle.Container>
      <CarouselSectionStyle.SectionContainer>
        <HStack>
          <CarouselSectionStyle.Title>{title}</CarouselSectionStyle.Title>
          <Spacer />
          {rightButton &&
            (rightButton.label ? (
              <div
                className="t4-regular text-palette-gray-700 flex-shrink-0"
                onClick={handleClickMore}
              >
                <HStack spacing={2} className="justify-center">
                  <span>{rightButton.label}</span>
                  <div className="h-3.5 w-3.5 [&_svg]:h-3.5 [&_svg]:w-3.5">{rightButton?.icon}</div>
                </HStack>
              </div>
            ) : (
              <div className="flex-center" onClick={handleClickMore}>
                {rightButton?.icon}
              </div>
            ))}
        </HStack>
      </CarouselSectionStyle.SectionContainer>
      <CarouselSectionStyle.List ref={scrollRef} onScroll={handleScroll}>
        {children}
      </CarouselSectionStyle.List>
    </CarouselSectionStyle.Container>
  );
};

export default CarouselSection;

export const CarouselSectionStyle = {
  Container: (props: StackProps) => (
    <Stack spacing={16} {...props} className={cn('py-3', props.className)} />
  ),
  SectionContainer: SectionContainer,
  List: forwardRef<HTMLDivElement, HStackProps>(({ children, className, ...props }, ref) => (
    <HStack
      spacing={10}
      className={cn('horizontal-scroll items-start px-4', className)}
      ref={ref}
      {...props}
    >
      {children}
    </HStack>
  )),
  Title: (props: HTMLAttributes<HTMLDivElement>) => (
    <h3 {...props} className={cn('t6-bold', props.className)} />
  ),
};
