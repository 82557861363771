const Path = {
  Main: '/',
  Articles: '/articles',
  NewArticle: '/articles/new',
  SimpleNewArticle: '/articles/new_simple',
  ArticleDraft: '/articles/draft',
  ArticleStepEdit: '/articles/step_edit/:stepId',
  Article: '/articles/:articleId',
  ArticleImages: '/articles/:articleId/images',
  ArticleCheckList: '/articles/:articleId/checklist',
  ArticleRoadView: '/articles/:articleId/road_view',
  ArticleCheckListMemo: '/articles/:articleId/checklist/memo',
  ArticleTradeTool: '/articles/:articleId/trade_tool',
  ArticleTradeToolDetail: '/articles/:articleId/trade_tool/:type',
  ArticleRequestVerify: '/articles/:articleId/request_verify',
  ArticleVerify: '/articles/:articleId/verify',
  EditArticle: '/articles/:articleId/edit',
  EditArticleImage: '/articles/:articleId/edit_image',
  PullupArticle: '/articles/:articleId/pullup',
  ArticleChannelList: '/articles/:articleId/channel_list',
  ArticleLandTitle: '/articles/:articleId/land_title',
  ArticleLandTitleCertificate: '/articles/:articleId/land_title/certificate',
  ArticleTradeSurvey: '/articles/:articleId/trade_survey',
  ArticleSunlight: '/articles/:articleId/sunlight',
  ArticleChangeCorAddress: '/articles/:articleId/change_cor_address',
  SearchAddress: '/search_address',
  SearchRegion: '/search_region',
  SearchUniversity: '/search_university',
  SearchJusoAddress: '/search_juso_address',
  SearchJusoUnit: '/search_juso_unit',
  Subscribe: '/subscribe',
  SubscribeSetting: '/subscribe_setting',
  SubscribeRegion: '/subscribe_region',
  SubscribeOnboarding: '/subscribe_onboarding',
  SatisfactionSurvey: '/satisfaction_survey',
  My: '/my',
  Review: '/review',
  ReviewNew: '/review/new',
  TradeReviewNew: '/trade_review/new',
  TradeReviewSelectBuyer: '/trade_review/select_buyer',
  SendReviews: '/send_reviews/:articleId',
  SellerReviews: '/seller_reviews',
  Filter: '/filter',
  Guide: '/guide/:salesType/:tradeType',
  ImageGuide: '/image_guide/:salesType',
  AlphaLogin: '/alpha_login',
  SmsIdentification: '/sms_identification/:articleId',
  MySalesHistory: '/my_sales_history',
  VerifyArticles: '/verify_articles',
  Faq: '/faq',
  SettingNotification: '/setting/notification/:type',
  ArticleCompare: '/article_compare',
  AdvertisementCreate: '/advertisement/create/:articleId',
  AIConversion: '/ai_conversion',
  RecentViewedArticles: '/recent_viewed_articles',
  BrokerPreOpenApplication: '/broker_pre_open_application',
  BrokerOnboarding: '/broker_onboarding',
  Complete: '/complete',
  BizProfileApprovalSetting: '/biz_profile/approval_setting',
  BizProfileCreate: '/biz_profile/create',
  BizProfileInfo: '/biz_profile/info',
  BizProfileReviews: '/biz_profile/:bizProfileId/reviews',
  BizProfileVerificationManage: '/biz_profile/verification_manage',
  BizProfileOwnerVerificationFailed: '/biz_profile/:bizProfileId/owner_verification/failed',
  BizProfileOwnerVerification: '/biz_profile/:bizProfileId/owner_verification',
  BizProfileBusinessCertificate: '/biz_profile/:bizProfileId/business_certificate',
  BizProfileArticles: '/biz_profile/:bizProfileId/articles',
  MarketingPriceSearch: '/marketing/price_search',
  MarketingPriceSearchResult: '/marketing/price_search/result',
  MyRealtyFortune: '/my_realty_fortune',
  MyRealtyFortuneResult: '/my_realty_fortune/result',
  Recap: '/recap',
  RecapResult: '/recap/result',
  FormBuildingUsage: '/form/building_usage',
  Map: '/map',
  BrokerHotArticles: '/broker_hot_articles/:region2Id',
} as const;

export const PathNames = {
  [Path.Main]: '부동산 홈',
  [Path.Articles]: '부동산 홈',
  [Path.NewArticle]: '매물 등록',
  [Path.SimpleNewArticle]: '간편 매물 등록',
  [Path.ArticleDraft]: '임시저장 매물',
  [Path.ArticleStepEdit]: '매물 등록 > 단계별 수정',
  [Path.Article]: '매물 상세',
  [Path.ArticleImages]: '매물 이미지 리스트',
  [Path.ArticleCheckList]: '매물 체크리스트',
  [Path.ArticleRoadView]: '매물 로드뷰',
  [Path.ArticleCheckListMemo]: '매물 체크리스트 메모',
  [Path.ArticleTradeTool]: '거래 도구',
  [Path.ArticleTradeToolDetail]: '매물 상세 > 거래 도구 상세',
  [Path.ArticleRequestVerify]: '집주인 인증 요청',
  [Path.ArticleVerify]: '집주인 인증',
  [Path.EditArticle]: '매물 수정',
  [Path.EditArticleImage]: '매물 이미지 수정',
  [Path.PullupArticle]: '매물 끌어올리기',
  [Path.ArticleChannelList]: '매물 채팅 목록',
  [Path.ArticleLandTitle]: '매물 등기부등본',
  [Path.ArticleLandTitleCertificate]: '매물 등기부등본 원본',
  [Path.ArticleTradeSurvey]: '거래 후기',
  [Path.ArticleSunlight]: '매물 3D 지도',
  [Path.SearchAddress]: '주소 검색',
  [Path.ArticleChangeCorAddress]: '매물 등기부 기반 주소 변경',
  [Path.SearchRegion]: '지역 검색',
  [Path.SearchUniversity]: '대학교 검색',
  [Path.SearchJusoAddress]: 'juso 기반 주소 검색',
  [Path.SearchJusoUnit]: 'juso 기반 주소 unit 검색 상세',
  [Path.Subscribe]: '새글 알림',
  [Path.SubscribeSetting]: '새글 알림 설정',
  [Path.SubscribeRegion]: '새글 알림 지역',
  [Path.SubscribeOnboarding]: '새글 알림 온보딩',
  [Path.SatisfactionSurvey]: '만족도 조사',
  [Path.My]: '마이페이지',
  [Path.Review]: '후기',
  [Path.ReviewNew]: '후기 작성',
  [Path.TradeReviewNew]: '거래 후기 작성',
  [Path.TradeReviewSelectBuyer]: '거래 후기 구매자 선택',
  [Path.SendReviews]: '보낸 후기 확인',
  [Path.SellerReviews]: '직접 계약한 판매자들의 후기',
  [Path.Filter]: '필터',
  [Path.Guide]: '직거래 가이드',
  [Path.ImageGuide]: '매물 작성 이미지 가이드',
  [Path.AlphaLogin]: '알파 로그인',
  [Path.SmsIdentification]: 'SMS 인증 (집주인 인증)',
  [Path.MySalesHistory]: '내 판매 내역',
  [Path.VerifyArticles]: '집주인인증 여러개',
  [Path.Faq]: 'FAQ (서비스 개선 제안 / 고객센터 문의)',
  [Path.SettingNotification]: '알림 설정 (매물 추천 알림 / 이웃들의 관심 알림)',
  [Path.ArticleCompare]: '매물 비교',
  [Path.AdvertisementCreate]: '광고 생성',
  [Path.AIConversion]: 'AI 글쓰기',
  [Path.RecentViewedArticles]: '최근 본 매물',
  [Path.BrokerPreOpenApplication]: '중개사 사전 신청',
  [Path.BrokerOnboarding]: '중개사 온보딩',
  [Path.Complete]: '완료',
  [Path.BizProfileApprovalSetting]: '중개사 비즈프로필 매물 간편 승인 설정',
  [Path.BizProfileCreate]: '중개사 비즈프로필 생성',
  [Path.BizProfileBusinessCertificate]: '중개사 비즈프로필 사업자 인증',
  [Path.BizProfileInfo]: '중개사 비즈프로필 정보',
  [Path.BizProfileReviews]: '중개사 비즈프로필 후기',
  [Path.BizProfileArticles]: '중개사 비즈프로필 매물 모아보기',
  [Path.MarketingPriceSearch]: '마케팅 > 시세 조회',
  [Path.MarketingPriceSearchResult]: '마케팅 > 시세 조회 결과',
  [Path.MyRealtyFortune]: '피처톤 > 부동산 운세',
  [Path.MyRealtyFortuneResult]: '피처톤 > 부동산 운세 결과',
  [Path.Recap]: '피처톤 > 연말결산',
  [Path.RecapResult]: '피처톤 > 연말결산 결과',
  [Path.FormBuildingUsage]: '매물 작성 > 건축물 용도',
  [Path.BizProfileOwnerVerificationFailed]: '중개사 비즈프로필 대표자 인증 실패',
  [Path.BizProfileOwnerVerification]: '중개사 비즈프로필 대표자 인증',
  [Path.BizProfileVerificationManage]: '중개사 인증 관리',
  [Path.Map]: '지도',
  [Path.BrokerHotArticles]: '중개사 인기매물',
} satisfies Record<(typeof Path)[keyof typeof Path], string>;

export default Path;
