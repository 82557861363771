import Placeholder from 'components/Base/Skeleton/Placeholder';
import MainRecommendSectionSkeleton from 'components/Base/Skeleton/MainRecommendSectionSkeleton';
import ArticleListSkeleton from 'components/Base/Skeleton/ArticleListSkeleton';
import { HStack } from 'components/Base/Stack/HStack';

const MainSectionSkeleton = () => {
  return (
    <div>
      <MainSectionSubscribeBannerSkeleton />
      <MainRecommendSectionSkeleton />
      <MainSectionListSkeleton />
    </div>
  );
};

export default MainSectionSkeleton;

export const MainSectionListSkeleton = () => {
  return (
    <div>
      <HStack spacing={8} className="px-4 pb-2 pt-1">
        <Placeholder.Chip size="small" width={96} />
        <Placeholder.Chip size="small" width={124} />
        <Placeholder.Chip size="small" width={68} />
        <Placeholder.Chip size="small" width={68} />
      </HStack>
      <div className="px-4 pb-2 pt-1">
        <Placeholder.Box className="h-[19] w-20" />
      </div>
      <div className="p-4">
        <ArticleListSkeleton />
      </div>
    </div>
  );
};

export const MainSectionSubscribeBannerSkeleton = () => {
  return (
    <div className="px-4 pb-2 pt-1">
      <Placeholder.Text className="t4-regular rounded-1.5 w-full p-4">
        1개 조건으로 알림 받는 중
      </Placeholder.Text>
    </div>
  );
};
