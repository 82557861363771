import ArticleCarouselSkeleton from 'components/Base/Skeleton/ArticleCarouselSkeleton';
import Placeholder from 'components/Base/Skeleton/Placeholder';

const MainRecommendSectionSkeleton = () => {
  return (
    <>
      <div className="pt-1">
        <div>
          <ArticleCarouselSkeleton />
        </div>
        <Placeholder.Divider />
        <div className="flex-center mt-1 py-3">
          <Placeholder.Text className="t4-regular">
            더 많은 매물 보기 더 많은 매물 보기
          </Placeholder.Text>
        </div>
      </div>
      <Placeholder.Box className="mb-3 h-2 w-full" />
    </>
  );
};

export default MainRecommendSectionSkeleton;
