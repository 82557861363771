import type { PropsOf } from 'utils/Type';
import { useActivity } from '@stackflow/react';
import { karrotBridge } from 'bridge';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { RouteName } from 'routes';
import { AppScreen, AppScreenProps } from 'seed-design/stackflow/AppScreenV3';
import { defaultScreenProps } from 'stackflow/components/DefaultScreenProps';
import RealtyAppBar from 'stackflow/components/RealtyAppBar';
import { PageWrapper } from 'components/Base/PageLayout';

export type AppBarProps = PropsOf<typeof RealtyAppBar>;
export type AppScreenOptions = Pick<AppScreenProps, 'preventSwipeBack'> & {
  appBar: AppBarProps;
};

type ScreenContextType = {
  screenOptions?: AppScreenOptions;
  setScreenOptions: React.Dispatch<React.SetStateAction<AppScreenOptions | undefined>>;
};

const ScreenContext = createContext<ScreenContextType>({
  screenOptions: undefined,
  setScreenOptions: () => undefined,
});

export const useScreenContext = () => useContext(ScreenContext);

const environment = karrotBridge.driver.getCurrentEnvironment();
const theme = environment === 'Android' ? 'android' : 'cupertino';

const ScreenLayout = ({ children }: PropsWithChildren) => {
  const [screenOptions, setScreenOptions] = useState<AppScreenOptions | undefined>();

  const { name } = useActivity();

  const defaultScreenOptions = defaultScreenProps[name as RouteName];

  return (
    <ScreenContext.Provider value={{ screenOptions, setScreenOptions }}>
      <AppScreen
        theme={theme}
        {...defaultScreenOptions}
        {...screenOptions}
        appBar={
          defaultScreenOptions?.withoutAppBar ? undefined : (
            <RealtyAppBar {...defaultScreenOptions?.appBar} {...screenOptions?.appBar} />
          )
        }
      >
        <PageWrapper>{children}</PageWrapper>
      </AppScreen>
    </ScreenContext.Provider>
  );
};

export default ScreenLayout;
