import useCustomFlow from './useCustomFlow';
import { useEffect } from 'react';
import useCamelCaseParams from './useCamelCaseParams';
import { findActivity } from 'stackflow/utils';

const useNavigateWithNextPath = () => {
  const { nextPath } = useCamelCaseParams<{ next_path?: string }>();
  const { push } = useCustomFlow();

  useEffect(() => {
    if (nextPath) {
      const activity = findActivity(nextPath);
      if (activity) {
        push(activity.name, activity.params);
      }
    }
  }, [nextPath, push]);
};

export default useNavigateWithNextPath;
