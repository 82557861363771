/**
 * @generated SignedSource<<60e103f956559d0d355e1eb537f4f7a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
import { FragmentRefs } from "relay-runtime";
export type ArticleMainTrade_article$data = {
  readonly trades: ReadonlyArray<{
    readonly deposit?: number;
    readonly description?: string | null;
    readonly monthlyPay?: number;
    readonly preferred: boolean;
    readonly price?: number;
    readonly type: TradeTypeEnum;
    readonly yearlyPay?: number;
  }>;
  readonly " $fragmentType": "ArticleMainTrade_article";
};
export type ArticleMainTrade_article$key = {
  readonly " $data"?: ArticleMainTrade_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleMainTrade_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleMainTrade_article"
};

(node as any).hash = "117e022f961e9742c63abe18244c29fb";

export default node;
