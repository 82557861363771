import { IconEyeFill } from '@daangn/react-monochrome-icon';
import { Image } from '@daangn/realty-react/ui';
import Thumbnail, {
  ThumbnailRatio,
  type ThumbnailProps,
  type ThumbnailRatioProps,
} from 'components/Base/Thumbnail';
import { graphql, useFragment } from 'react-relay';
import { getThumbnailUrl } from 'utils/Image';
import { ArticleThumbnail_article$key } from '__generated__/ArticleThumbnail_article.graphql';
import FontSizeLimiter from 'components/Base/FontSizeLimiter';
import IconPlayWithDimmed from 'components/icons/IconPlayWithDimmed';
import { HStack } from 'components/Base/Stack/HStack';

type Props = {
  articleRef: ArticleThumbnail_article$key;
  lazy?: boolean;
  isWatched?: boolean;
  showVideoIcon?: boolean;
} & (ThumbnailProps | ThumbnailRatioProps);

const ArticleThumbnail = ({
  articleRef,
  lazy = true,
  isWatched,
  showVideoIcon,
  ...thumbnailProps
}: Props) => {
  const article = useFragment(
    graphql`
      fragment ArticleThumbnail_article on Article {
        id
        imageThumbnailUrl
        videoThumbnailUrl
        images {
          id
        }
        videos {
          id
        }
      }
    `,
    articleRef
  );
  const hasVideo = article.videos.length > 0;
  const hasImage = article.images.length > 0;

  const thumbnailUrl =
    !hasImage && article.videoThumbnailUrl ? article.videoThumbnailUrl : article.imageThumbnailUrl;
  const isRatioThumbnail = 'ratio' in thumbnailProps;
  const ThumbnailComponent = isRatioThumbnail ? ThumbnailRatio : Thumbnail;

  return (
    <div>
      <ThumbnailComponent {...(thumbnailProps as ThumbnailProps & ThumbnailRatioProps)}>
        <Image
          lazy={lazy}
          src={getThumbnailUrl(thumbnailUrl)}
          width="100%"
          height="100%"
          style={{ objectFit: 'cover' }}
        />
        {isWatched && (
          <div className="t1-bold z-docked text-palette-static-white absolute inset-x-0 top-0 bg-gradient-to-b from-[rgba(0,0,0,0.25)] via-[rgba(0,0,0,0.12)] to-transparent p-[6px_4px_40%]">
            <HStack spacing={2}>
              <IconEyeFill width={12} height={12} />
              <FontSizeLimiter limit={10}>
                <span>본 매물</span>
              </FontSizeLimiter>
            </HStack>
          </div>
        )}
        {showVideoIcon && hasVideo && (
          <div className="text-palette-static-white absolute bottom-0 right-0 flex">
            <IconPlayWithDimmed />
          </div>
        )}
      </ThumbnailComponent>
    </div>
  );
};

export default ArticleThumbnail;
